import React, {useContext} from 'react'
import {Link} from "react-router-dom"
import {ReactComponent as PenyesaLogo} from "../Images/PenyesaLogoWhite.svg"
import {ReactComponent as PaypalLogo} from "../Images/PaypalLogo.svg"
import {ReactComponent as PayfastLogo} from "../Images/PayfastLogo.svg"
import {ReactComponent as VisaLogo} from "../Images/VisaLogo.svg"
import {ReactComponent as MastercardLogo} from "../Images/MastercardLogo.svg"
import {AuthContext} from "../../Context/Auth.Context"
import CartImage from "../Images/trolleyBig.png"
import Login from "../../auth/Components/Login"
import Flag from "../Images/zimFlag.png"
import "../Styles/TopView.scss"

import Info from "./Info"

const TopView = () => {
    const {currentAuthState} = useContext(AuthContext);
    return (
        <div className="topview">
           
            <div className="toplogo-container">
                <PenyesaLogo className="toplogo-logo"/>
            </div>
            <div className="topview-gridContainer">
                <div className="topview-flank">
                    <Info/>
                    <div className="payment-container topViewHide">
                        <PayfastLogo className="payment-icon"/>
                        <div className="payment-brands">
                            <MastercardLogo className="payment-brands--logo"/>
                            <VisaLogo className="payment-brands--logo"/>
                        </div>
                    </div>
                    {/* <div className="borderBottom"></div> */}
                </div>
                <div className="topview-middle">
                    <Link to="/category/Food">
                    <button className="btn-main">Shop now</button>
                    </Link>
                    <img src={CartImage} alt="Penyesa Trolley Display" className="top-image"/>
                    <div className ="top-text">
                        <p>Shop online for groceries</p>
                        <p>delivered to Zimbabwe.</p>
                        <p>Delivery within 24 to 48 hours</p>
                        
                        
                    </div>
                    <img src={Flag} className="top-flag" alt="Zimbabwe Flag for deliveries to Zimbabwe"/>


                </div>
                <div className="topview-flank">
                    {
                        currentAuthState !== "signedin" ? <Login/> : (
                            <>
                                <Info/>
                                <div className="payment-container">
                                    <PaypalLogo className="payment-icon"/>
                                    <PayfastLogo className="payment-icon payment-hide-show"/>
                                    <div className="payment-brands">
                                        <MastercardLogo className="payment-brands--logo"/>
                                        <VisaLogo className="payment-brands--logo"/>
                                    </div>
                                </div> 
                            </>
                        )
                    }
                    {/* <Info/>
                    <div className="payment-container">
                        <PaypalLogo className="payment-icon"/>
                        <PayfastLogo className="payment-icon payment-hide-show"/>
                        <div className="payment-brands">
                            <MastercardLogo className="payment-brands--logo"/>
                            <VisaLogo className="payment-brands--logo"/>
                        </div>
                    </div> */}
                </div>
            </div>
            
        </div>
    )
}

export default TopView
