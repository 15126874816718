import React, {useContext, useState, useEffect} from 'react'
import "../Styles/Category.scss"
import Card from "../../Shared/Components/Card"
import {ReactComponent as CategoryLogo} from "../Images/PenyesaLogoWhite.svg"
import { Link, useParams} from "react-router-dom"
import {ProductsContext} from "../../Context/Products.Context"
import Footer from '../../Shared/Components/Footer'
import CartButton from '../../Shared/Components/CartButton'
import Pagination from "./Pagination"
import ChristmasIcon from "../Images/christmasBell.gif"
import {Helmet} from "react-helmet"
import SearchBar from './SearchBar';



const MetaData = {
    Baskets : {
        title : "Penyesa Pantry Baskets | Quality Grocery hampers",
        description : "Penyesa Baskets are carefully packed, to offer essential grocery hampers at great value.Packed from a wide range of every-day products"
    },
    Food :{
        title : "Penyesa Food Pantry | Buy Groceries Online",
        description : "Penyesa Food Pantry has a wide selection of your favourite daily items. Buy cooking ingredients,baking essentials,snacks and much more all online... "
    },
    Health :{
        title : "Penyesa Health & Beauty | Shop Online",
        description : "You deserve to shine!Shop online for quality and great value Health & Beauty Products.Deliver to Zimbabwe"
    },
    Household : {
        title : "Penyesa Household | Household Products Online",
        description : "Buy household essentials from quality brands from our online Pantry. Buy and send top quality household items to Zimbabwe."
    },
    Gifts : {
        title : "Penyesa Gifts | Shop for treats and gourmet hampers ",
        description : "Surprise your loved ones with treats and gourmet hampers.Buy and send gifts for special occassions.Shop for non alcoholic cocktails, chocolates "
    }
}

function Category() {
    const {title} = useParams();
    const {products, isLoading} = useContext(ProductsContext);

    //Set Up pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(16);
    const [initialCategoryProducts, setInitialCategoryProducts] = useState([])
    const [categoryProducts, setCategoryProducts] = useState([])

    const handleFilter = (searchTerm) => {
        if (!searchTerm) setCategoryProducts(initialCategoryProducts);
       const filteredProducts = initialCategoryProducts.filter(
         (product) =>
           product?.brand?.toLowerCase().includes(searchTerm.toLowerCase()) ||
           product?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
           product?.description
             ?.toLowerCase()
             .includes(searchTerm.toLowerCase())
        );

       setCategoryProducts(filteredProducts);
     };


    const categories = {
        baskets :   (
            <Link key={`categrory-${1}`} onClick={() => setCurrentPage(1)} to="/category/Baskets">
                <div className={`category-card cat-card ${title === "Baskets" && "cat-active"}`} key="baskets-cat" name="baskets">
                    <h1 className="category-heading cat-heading ">
                        <i className="fas fa-shopping-basket"></i>
                        <div className="cat-text">Baskets</div>
                    </h1>
                </div>
            </Link>
        ) ,
        pantry : (
            <Link key={`categrory-${2}`} onClick={() => setCurrentPage(1)} to="/category/Food">
                <div className={`category-card cat-card ${title === "Food" && "cat-active"}`} key="pantry-cat"name="pantry">
                    <h1 className="category-heading cat-heading">
                        <i className="fas fa-bread-slice"></i>
                        <div className="cat-text">Food Pantry</div>
                    </h1>
                </div>
            </Link>
        ),
        health : (
            <Link key={`categrory-${3}`} onClick={() => setCurrentPage(1)} to="/category/Health">
                <div className={`category-card cat-card ${title === "Health" && "cat-active"}`} key="health-cat" name="health">
                    <h1 className="category-heading cat-heading">
                        <i className="fas fa-hand-sparkles"></i>
                        <div className="cat-text">Health & Beauty</div>
                    </h1>
                </div>
            </Link>
        ),
        household :(
            <Link key={`categrory-${4}`}  onClick={() => setCurrentPage(1)} to="/category/Household">
                <div className={`category-card cat-card ${title === "Household" && "cat-active"}`} key="household-cat" name="household">
                    <h1 className="category-heading cat-heading">
                        <i className="fas fa-sink"></i>
                        <div className="cat-text">Household</div>
                    </h1>
                </div>
            </Link>
        ),
        gifts : (
            <Link key={`categrory-${5}`} onClick={() => setCurrentPage(1)} to="/category/Gifts">
                <div className={`category-card cat-card ${title === "Gifts" && "cat-active"}`} key="gifts-cat" name="gifts">
                    <h1 className="category-heading cat-heading">
                        <img className="category-icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACH0lEQVRoge2Zv0scQRiGn5MgXBcvNgpCTGds8xdICiWoYGKhMQoWYmWRQMRCsYilYpm0ChamuiKk00qLkCbp9Q9Iogj+gNN4m2LfvTtOd3b39oRxMw9ste+83/fuzM4Od/Af0QJMAbvAEVACDoGPwNMm1ukFPsm7pFo7wKR6SEUL8BnwQq4rYBHIpaiRA5bkFVZnm5RhZmX0BxgDCkAr0AOs1RT/kKLGCtWHsirvVtUaV20PmElRg32ZvAm5PwBcAmXgdQP+Exp7Ka/bmFQPew34VziWSZtBMy3NOfA4gXcXcKqxswZdQZqjBN43CII8jNAF71ExgXeR6vo30SbdcQLvG3yXSV+ErhM4kXYwhu+QtCdAR4T2ubTfYviGsiCTL0TvTHPSHgB5gy6Pv8V6GmMiB3yVdj5Gv6G0A79ltBShfQD8lHbdoFuX5ofGmFiW9hfwKEa/Rl4Af2W4hXkpPMP/mJWBkVvuv9S9krRhdKqWp9r9ibsOYRg4k/EFsAG8wt+l6pfRu5oG3uO/rAX8pRE8kLd1Y/JANzAKbKqGp5pDzQoR0IV/hLgm/AvcrKuMv5s9idtc8AJ7jeezglzqw5gt1O8aaQ5/UJ3ZKJ+4urj10h+PbcEFsQ0XxDYyE8R9EG0nOOvc9fim18nMjLggtuGC2IYLYhsuiG24ILbhgtiGC2IbLohtuCC24YLYRmaC1P8/cd9+qKv0n5kZyQz/AH1rn163HWE5AAAAAElFTkSuQmCC"/>
                        <div className="cat-text">Gifts</div>
                    </h1>
                </div>
            </Link>
        )

    }

    const indexOfLastPage = currentPage * itemsPerPage;
    const indexOfFirstPage = indexOfLastPage - itemsPerPage;


    //Change Page
    const handlePaginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    useEffect(() => {
      console.log(products);
      const productsInCategory = products.filter((product) => {
        return product.category === title || product.category === "Temp";
      });
      const currentItems = productsInCategory.slice(
        indexOfFirstPage,
        indexOfLastPage
      );

      setInitialCategoryProducts(currentItems);
      setCategoryProducts(currentItems);
    }, [
      setCategoryProducts,
      indexOfFirstPage,
      indexOfLastPage,
      title,
      isLoading,
    ]);

    useEffect(() => {
        window.scrollTo({
            top : 30,
            left : 0,
            behavior: "smooth"
        });
    }, [currentPage])
    return (
      <>
        <div className="cat">
          <Helmet>
            <title>{MetaData[title].title}</title>
            <meta name="description" content={MetaData[title].description} />
          </Helmet>
                <div className="cat-display">
                    <div></div>
            <CategoryLogo className="cat-logo" />
            <SearchBar onFilter={handleFilter} />
          </div>
          <div className="category">
            {Object.keys(categories).map((key) => categories[key])}
          </div>
          <div className="services">
            <div className="services-heading">
              <hr className="services-rule" />
              {/* <img src={ChristmasIcon} className="services-promo" /> */}
              <h1 className="services-heading--text">{title}</h1>
              {/* <img src={ChristmasIcon} className="services-promo" /> */}
              <hr className="services-rule" />
            </div>
            <div className="shop">
              {categoryProducts.map((card) => (
                <Card key={`cat-${card.id}`} product={{...card}} />
              ))}
            </div>
            <Pagination
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={products.filter((product) => {
        return product.category === title;
      }).length}
              paginate={handlePaginate}
            />
          </div>
        </div>
        <CartButton />
        <Footer />
      </>
    );
}

export default Category
