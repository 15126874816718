import React from 'react'
import "../Styles/Success.scss"
const SuccessOffline = () => {
    return (
        <div>
            <div style={{height: "100vh"}} className="success">
                <div className="success-container">
                    <div className="success-header">
                        <h1 className="success-heading">
                            {`Payment Confirmation Status : Order Placed Successfully`}
                        </h1>
                    </div>
                        <div className="success-message">
                        Thank you for your order.This confirms that we have received your order which we have placed on hold until we can confirm that payment has been received. Please use the payment reference provided or full name as reference and kindly send the proof of payment to info@penyesa.co.za
                          <p>
                            A copy of your invoice has been emailed to you.
                          </p>
                        </div>
                    
                </div>
            </div>
        </div>
    )
}
export default SuccessOffline
