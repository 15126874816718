import React from 'react'
import "../Styles/Pagination.scss"
const Pagination = ({itemsPerPage, totalItems, paginate, currentPage}) => {
    const pageNumbers = [];
    for(let i =1; i <= Math.ceil(totalItems/itemsPerPage); i++){
        pageNumbers.push(i)
    }
    const handlePaginate = (event) => {
        const {id} = event.target;
        if(id === "back"){
            currentPage > 1 && paginate(currentPage - 1) 
        }else{
            currentPage < Math.ceil(totalItems/itemsPerPage) && paginate(currentPage + 1)
        }
        
    }
    return (
        <div className="pagination">
        <i className={`fas fa-chevron-left page-icon ${currentPage === 1 && "page-disabled"}`} id="back" onClick={handlePaginate }></i>
            {
                pageNumbers.map(element => <p onClick={() => paginate(element)} className={`page-number ${currentPage === element && "page-number--active"}`} key={`pg-${element}`}>{`${element}`}</p>)
            }
        <i className={`fas fa-chevron-right page-icon ${currentPage ===  Math.ceil(totalItems/itemsPerPage) && "page-disabled"}`} id="next" onClick={handlePaginate }></i>
    </div>
    )
}

export default Pagination
