import React from 'react'
import {Link} from "react-router-dom"
import "../Styles/Category.scss"


const Category = () => {
    return (
        <div className="category">
            <div className="category-card" name="baskets">
                
                <h1 className="category-heading">
                    Baskets
                </h1>
                <div className="category-text">
                    <p>Try our affordable range of carefully picked</p>
                    <p>pantry food, and home products that come</p>
                    <p>in our <span>Essentials</span>,<span>Standard</span></p>
                    <p>or <span>Deluxe</span> baskets</p>
                </div>
                <Link to ="/category/Baskets">
                    <button className="btn-main">Shop</button>
                </Link>
            </div>
            <div className="category-card" name="pantry">
                <h1 className="category-heading">
                    Food Pantry
                </h1>
                <div className="category-text">
                    <p>Take your pick from the wide variety</p>
                    <p>of quality food items, sourced</p>
                    <p>from only the best suppliers</p>
                    <p>in South Africa</p>
                </div>
                <Link to ="/category/Food">
                    <button className="btn-secondary">Shop</button>
                </Link>
            </div>
            <div className="category-card" name="health">
                <h1 className="category-heading">
                    Health & Beauty
                </h1>
                <div className="category-text">
                    <p>Shop for premier or luxurious health,</p>
                    <p>beauty & lifestyle products for </p>
                    <p>a refreshing & bright</p>
                    <p><span>Penyesa</span> look & feel.</p>
                </div>
                <Link to ="/category/Health">
                    <button className="btn-main">Shop</button>
                </Link>
            </div>
            <div className="category-card" name="household">
                <h1 className="category-heading">
                    Household
                </h1>
                <div className="category-text">
                    <p>Our household & cleaning product range is</p>
                    <p>affordable, safe and easy on the pocket.</p>
                    <p>Mix-n-match specials and baskets</p>
                    <p>for maximum value</p>
                </div>
                <Link to ="/category/Household">
                    <button className="btn-secondary">Shop</button>
                </Link>
            </div>
            <div className="category-card" name="gifts">
                <h1 className="category-heading">
                    Gifts
                </h1>
                <div className="category-text category-text-white">
                    <p>Surprise your loved ones with gourmet hampers.</p>
                    <p>Treat them to Royalty or allow them to</p>
                    <p>celebrate with our “Lets celebrate” </p>
                    <p>gift box.</p>
                </div>
                <Link to ="/category/Gifts">
                    <button className="btn-main">Shop</button>
                </Link>
            </div>
        </div>
    )
}

export default Category
