import React, {useEffect, useState} from 'react'
import Backdrop from "./BackDrop";
import {useHistory, useParams} from "react-router-dom"
import "../Styles/Success.scss"
import axios from "axios"


const Success = () => {
    const history = useHistory()
    const {id} = useParams();
    const [foundState, setFoundState] = useState(false)
    const [isLoadingState, setIsLoadingstate] = useState(true);
    const [paymentData,setPaymentData] = useState({})

    
    const fetchOrder = async (id) =>{
        try{
            const response = await axios.get(`https://rartx1msad.execute-api.eu-west-1.amazonaws.com/Dev/pendingorders/Pantry/${id}`)
            const order = response.data
            if(order.Order_ID){
                setPaymentData({
                    Order_ID : order.Order_ID,
                    billName : order.billName,
                    
                })
                setFoundState(true)
                setIsLoadingstate(false)
            }else{
                setFoundState(false)
                setIsLoadingstate(false)
                throw Error("Not Found")
            }
            
        }catch(err){
            console.log(err)
          
        }
    }
    useEffect(() => {
           
        fetchOrder(id);
        
    },[])

    return (
        <>
            <Backdrop openState={isLoadingState}/>
            <div className="success">
                <div className="success-container">
                    <div className="success-header">
                        <h1 className="success-heading">
                            {`Payment Confirmation Status : ${foundState ? "Success" : ""}`}
                        </h1>
                    </div>
                    {
                        foundState ? (
                            <div className="success-message">
                            {`Thank you ${paymentData.billName}. Your payment refernce is ${paymentData.Order_ID}. We have emailed your order confirmation, we will
                            send you an update when your order has been shipped. For enquires contact us at info@penyesa.co.za `}
                        </div>
                        ) : (
                            !isLoadingState && (
                                <div className="success-message">
                                    Sorry, No Payment information found...If you made a payment. Please contact us at info@penyesa.co.za 
                                </div>
                            )
                        )
                    }
                    
                </div>
            </div>
        </>
    )
}

export default Success
