import React, {useState, useContext} from 'react'
import "../Styles/Signup.scss"
import {Link} from "react-router-dom"
import FormError from './FormError';
import Loader from "../../Shared/Components/Loader"
import {AuthContext} from "../../Context/Auth.Context"


const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const formValid = (signupErrorData) => {
    let valid = true;
    // validate form errors being empty
    Object.values(signupErrorData).forEach(val => {
        val.length > 0 && (valid = false);
      });
  
    return valid;
  };

const hasUpperCase = (word) => {
    return word.toLowerCase() !== word
}
const hasLowerCase = (word) => {
    return word.toUpperCase() !== word;
}

const Signup = () => {
    const [inputState, setInputState] = useState({
        signupFirstName : "",
        signLastName : "",
        signupMobile : "",
        signupEmail : "",
        signupAddressOne : "",
        signupSuburb : "",
        signupCity : "",
        signupPostCode : "",
        signupProvince : "",        
        signupPassword : "",
        signupConfirmPassword : "",
        retryEmail : ""
        
    });
    const [signupError,setSignupError] = useState({
        signupFirstName : "First name is required",
        signupLastName : "Last name is required",
        signupMobile : "Mobile number is required",
        signupEmail : "Email is required",
        signupAddressOne : "Address is required",
        signupSuburb : "Suburb is required",
        signupCity : "City is required",
        signupPostCode : "Post code is required",
        signupProvince : "",        
        signupPassword : "Password is required",
        signupConfirmPassword : "Password does not match"
    });

  
    const [retryEmailError, setretryEmailError] = useState({
        retryEmail : "Email Required"
    })
    
   
    const {currentAuthState,userEmail, handleSignUp,handleResendVerification, handleNavState,
            errorAuthState,isLoading,resetError} = useContext(AuthContext);
    const [isInitial, setisInitial] = useState(true)
    const handleInputChange = (event) => {
        //Validate Error posibility on value change
        const {name, value} = event.target;
        setInputState({
            ...inputState, 
            [name] : value
        })
        let tempVal = "";
        switch(name){
            case "signupFirstName" : 
                tempVal = value.length < 1 ? "First name is required" : ""
                setSignupError({
                    ...signupError, signupFirstName : tempVal
                })
            break;
            case "signupLastName" : 
                tempVal = value.length < 1 ? "Last name is required" : ""
                setSignupError({
                    ...signupError, signupLastName : tempVal
                })
            break;
            case "signupMobile" : 
                tempVal = value.length < 1 ? "Mobile number is required" : ""
                setSignupError({
                    ...signupError, signupMobile : tempVal
                })
            break;
            case "signupEmail" :
                
                if(value.length > 0){
                    resetError();
                    tempVal = emailRegex.test(value) ? "" : "Provided Email Invalid"
                }
                setSignupError({
                    ...signupError, signupEmail : tempVal
                })
            break;
            case "signupAddressOne" : 
                tempVal = value.length < 1 ? "Address is required" : ""
                setSignupError({
                    ...signupError, signupAddressOne : tempVal
                })
            break;
            case "signupSuburb" : 
                tempVal = value.length < 1 ? "Suburb is required" : ""
                setSignupError({
                    ...signupError, signupSuburb : tempVal
                })
            break;
            case "signupCity" :
                tempVal = value.length < 1 ? "City is required" : ""
                setSignupError({
                    ...signupError, signupCity : tempVal
                })
            break;
            case "signupPostCode" :
                tempVal = value.length < 1 ? "Post code is required" : ""
                setSignupError({
                    ...signupError, signupPostCode : tempVal
                })
            break;
            
            case "signupPassword" : 
                if( value.length < 1){
                    tempVal = "Password is required"
                }else if(value.length >= 1 && value.length < 8){
                    tempVal = "Password length should be greater than 7  " 
                }else{
                    tempVal = hasUpperCase(value) && hasLowerCase(value) ? "" : "Password must contain at lest one upper case letter and one lower case letter";
                }
                
                setSignupError({
                    ...signupError, signupPassword : tempVal
                })
            break;
            case "signupConfirmPassword" :
                if(value.length > 0){
                    tempVal = value === inputState.signupPassword ? "" : "Password does not match"
                }
                setSignupError({
                    ...signupError, signupConfirmPassword : tempVal
                })
            break;
            case "retryEmail" : 
                if(value.length > 0){
                        tempVal = emailRegex.test(value) ? "" : "Provided Email Invalid"
                    }
                    setretryEmailError({
                        ...retryEmailError, retryEmail : tempVal
                    })
            break;
            default : 
                setretryEmailError({
                    ...retryEmailError
                })
                setSignupError({
                    ...signupError
                })
            break;
        } 

    }
    const handleRetrySubmit = async (event) => {
        event.preventDefault();
        setisInitial(false)
        if(formValid(retryEmailError)){
            await handleResendVerification({
                email : inputState.retryEmail
            })
        }
        
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setisInitial(false)
        if (formValid(signupError)) {
                await handleSignUp({
                first_name : inputState.signupFirstName,
                last_name : inputState.signupLastName,
                email : inputState.signupEmail,
                mobile_number : inputState.signupMobile,
                address_line_1 : inputState.signupAddressOne,
                surburb : inputState.signupSuburb ,
                city   : inputState.signupCity,
                province : inputState.signupProvince,
                post_code : inputState.signupPostCode,
                password : inputState.signupConfirmPassword,
                
            })
            setisInitial(true);
          } else {
            console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
          }
          
    }
    const signupMessage = (value) => {

        switch(value){
          case "confirmsignup" : 
          return <div className="signup-paragraph">
                  {`We have sent an email to ${userEmail} containing a verification link. Please click the link to complete your account registration and be able to login.`}
                  <p className="signup-resend">Have not received your confirmation code/link? <span className="signup-link signup-resend_link" onClick={() => handleNavState("resendconfirmation")}>Resend confirmation </span></p>
              </div>
            case "resendconfirmation" :
                return <div className="signup-resend_retry">
                            <div className="signup-paragraph">
                                Please enter the email you used during sign up to resend the link to confirm your account.
                            </div>
                            <div className="retry-form" id="signup">
                                <div className="signup-form-input ">
                                    <label className="signup-form-label" htmlFor="retryEmail">Email</label>
                                    <input 
                                        onChange={handleInputChange}
                                        className="signup-input" required type="email" 
                                        name="retryEmail" id="retryEmail"/>
                                        {
                                            !isInitial && retryEmailError.retryEmail.length > 0 && <FormError error={retryEmailError.retryEmail}/>
                                        }
                                    
                                </div>
                                <div className="signup-button-group">
                                    {
                                        isLoading && <Loader/>
                                    }
                                    <button className="signup-btn retry-btn" name="next-retry" onClick={handleRetrySubmit}>Resend Confirmation</button>     
                                </div>
                            </div>
                        </div>
          case "confirmation_resent" :
          return <div className="signup-paragraph">
                  {`Email has been resent to ${userEmail}. Please check your mailbox or contact info@penyesa.co.za for assistance.`}
                  
              </div>
            default :
            return <div className="signup-paragraph">
                        Create a Penyesa account to get exclusive offers and for a faster and more secure shopping experience. 
                        {
                            errorAuthState.name === "UsernameExistsException" && <FormError error={"A user with the given email already exists."}/>
                        } 
                    </div>          
        }
    }
    return (
        <div className="signup">
            
            <form className="signup-form " id="signup" onSubmit={handleSubmit} noValidate>
                <div className="signup-header">
                    <span className="signup-title">Signup</span>
                </div>
                {
                   signupMessage(currentAuthState)
                }
                {
                    currentAuthState === "signup" &&
                    <>
                    <div className="signup-form-group">
                        <div className="signup-form-row">
                            <div className="signup-form-input">
                                <label className="signup-form-label" htmlFor="signupFirstName">First Name</label>
                                <input 
                                    onChange={handleInputChange} 
                                    className="signup-input" required 
                                    type="text" name="signupFirstName" id="signupFirstName"/>
                                    {
                                        !isInitial && signupError.signupFirstName.length > 0 && <FormError error={signupError.signupFirstName}/>
                                    }
                            </div>
                            <div className="signup-form-input">
                                <label className="signup-form-label" htmlFor="signupLastName">Last Name</label>
                                <input 
                                    onChange={handleInputChange}
                                    className="signup-input" required type="text" 
                                    name="signupLastName" id="signupLastName"/>
                                    {
                                        !isInitial && signupError.signupLastName.length > 0 && <FormError error={signupError.signupLastName}/>
                                    }
                            </div>
                        </div>
                    
                        <div className="signup-form-row">
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupMobile">Phone</label>
                                <input 
                                    onChange={handleInputChange} 
                                    className="signup-input" required type="text" 
                                    name="signupMobile" id="signupMobile"/>
                                    {
                                        !isInitial && signupError.signupMobile.length > 0 && <FormError error={signupError.signupMobile}/>
                                    }
                            </div>
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupEmail">Email</label>
                                <input 
                                    onChange={handleInputChange} 
                                    className="signup-input" required type="email" 
                                    name="signupEmail" id="signupEmail"/>
                                    {
                                        !isInitial && signupError.signupEmail.length > 0 && <FormError error={signupError.signupEmail}/>

                                    }
                                    {
                                        
                                    }
                            </div>
                        </div>

                        <div className="signup-form-input">
                            <label className="signup-form-label" htmlFor="signupAddressOne">Address</label>
                            <input 
                                onChange={handleInputChange} 
                                className="signup-input" required type="text" 
                                name="signupAddressOne" id="signupAddressOne"/>
                                {
                                    !isInitial && signupError.signupAddressOne.length > 0 && <FormError error={signupError.signupAddressOne}/>
                                }
                        </div>
                        <div className="signup-form-row">
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupSuburb">Suburb</label>
                                <input 
                                    onChange={handleInputChange}
                                    className="signup-input" required type="text" 
                                    name="signupSuburb" id="signupSuburb"/>
                                {
                                        !isInitial && signupError.signupSuburb.length > 0 && <FormError error={signupError.signupSuburb}/>
                                    }
                            </div>
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupCity">City</label>
                                <input 
                                    onChange={handleInputChange}
                                    className="signup-input" required type="text" 
                                    name="signupCity" id="signupCity"/>
                                {
                                        !isInitial && signupError.signupCity.length > 0 && <FormError error={signupError.signupCity}/>
                                    }
                            </div>
                                                    
                        </div>
                        <div className="signup-form-row">
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupProvince">Province</label>
                                <input 
                                    onChange={handleInputChange} 
                                    className="signup-input" required type="text" 
                                    name="signupProvince" id="signupProvince"/>
                                {
                                        !isInitial && signupError.signupProvince.length > 0 && <FormError error={signupError.signupProvince}/>
                                    }
                            </div>
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupPostCode">Post Code</label>
                                <input 
                                    onChange={handleInputChange}
                                    className="signup-input" required type="text" 
                                    name="signupPostCode" id="signupPostCode"/>
                                {
                                        !isInitial && signupError.signupPostCode.length > 0 && <FormError error={signupError.signupPostCode}/>
                                    }
                            </div>
                                                    
                        </div>
                        <div className="signup-form-row">
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupPassword">Password</label>
                                <input 
                                    type="password"
                                    onChange={handleInputChange}
                                    className="signup-input" required 
                                    name="signupPassword" id="signupPassword"/>
                                {
                                        !isInitial && signupError.signupPassword.length > 0 && <FormError error={signupError.signupPassword}/>
                                    }
                            </div>
                            <div className="signup-form-input ">
                                <label className="signup-form-label" htmlFor="signupConfirmPassword">Confirm Password</label>
                                <input 
                                    type="password"
                                    onChange={handleInputChange}
                                    className="signup-input" required 
                                    name="signupConfirmPassword" id="signupConfirmPassword"/>
                                {
                                        !isInitial && signupError.signupConfirmPassword.length > 0 && <FormError error={signupError.signupConfirmPassword}/>
                                    }
                            </div>
                                                    
                        </div>
                        
                        
                    </div>
                    <div className="signup-button-group">
                        {
                            isLoading && <Loader/>
                        }
                        {/* <button className="form-back" name="back" onClick={() => history.push("/")}><span className="form-back-text">&#8592;</span>back</button> */}
                        <button className="signup-btn" name="next" type="submit" form="signup">Sign up</button>     
                    </div>
                    <h1 className={`signup-cta`}>
                    {` Already Have a Penyesa account? `}
                        {
                            <Link className="signup-link" to="/">LOGIN</Link>
                        } 
                    </h1>
                </>
                }
            </form>
            

        </div>
    )
}

export default Signup
