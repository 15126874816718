import React,{useState, useContext} from 'react'
import "../Styles/ForgotPass.scss"
import FormError from './FormError';
import {AuthContext} from "../../Context/Auth.Context"
import Loader from "../../Shared/Components/Loader"

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);


const ForgotPass = () => {

    const {isLoading, currentAuthState,resetPassword,resetPasswordConfirm} = useContext(AuthContext)
    const [emailInputState, setEmailInputState] = useState("");
    const [emailErrorState, setEmailErrorState] = useState("Email Required")
    const [isSubmit, setIsSubmit] = useState(false);
    const [isInitial, setIsInitial] = useState(true)

    const handleInputChange = (event)  => {
        const {value} = event.target;
        setEmailInputState(value.toLowerCase());
        let tempVal = "";
        if(value.length > 0){
            tempVal = emailRegex.test(value) ? "" : "Provided email is invalid"
        }
        setEmailErrorState(tempVal);

    }
    const handleSubmit = () => {
        setIsInitial(false)
        if(emailErrorState.length < 1){
            resetPassword(emailInputState.toLowerCase());
           setTimeout(() => {
            setIsSubmit(true)
           },500)
        }

    }


    return (
        <div className="forgotpass">
        <div className="forgotpass-container">
            <h1 className="forgotpass-title">
               {!isSubmit ? "Forgot your Password?" : "Reset Password" }
            </h1>
            <p className="forgotpass-p">
                {!isSubmit ? "Enter your email below to receive a link to reset your password." : `An email has been sent to ${emailInputState}. Please check your email and follow the provided link to reset your password`}
            </p>
            {
                !isSubmit && (
                    <div className="retry-form" >
                        <div className="signup-form-input ">
                            <label className="signup-form-label" htmlFor="forgotPass">Email</label>
                            <input 
                                onChange ={handleInputChange}
                                className="signup-input" required type="email" 
                                name="forgotPass" id="forgotPass"/>
                                {
                                    !isInitial && emailErrorState.length > 0 && <FormError error={emailErrorState}/>
                                }
            
                        </div>
                        <div className="signup-button-group">
                            {
                                isLoading && <Loader/>
                            }
                            <button className="signup-btn retry-btn" name="next-pass"
                            onClick={handleSubmit} disabled={isSubmit === true ? true :  false}>reset password</button>     
                        </div>
                    </div>
                )
            }
            <div className="signup-button-group">
                {/* <Link className="forgotpass-btn" to="/" >Sign in <span className="forgotpass-arrow">&#8603;</span></Link>      */}
            </div>
        </div>
        
    </div>
    )
}

export default ForgotPass
