import React, {useContext,useEffect} from 'react'
import {useHistory} from "react-router-dom"
import {ControlContext} from "../../Context/Control.Context"
import TopView from './TopView'
import "../Styles/util.scss"
import Category from './Category'
import Promotions from "../../Shared/Components/Promotions"
import Browse from './Browse'
import Footer from '../../Shared/Components/Footer'
import CartButton from '../../Shared/Components/CartButton'
import {Helmet} from "react-helmet"
import { AuthContext } from '../../Context/Auth.Context'



const Home = (props) => {
    const {handleRetrieveSession} = useContext(AuthContext)
    const history = useHistory();
    const {controlDispatch,promoState} = useContext(ControlContext);

    useEffect(() => {
        const getUserInfo = async () => {
            await handleRetrieveSession();
        }
      getUserInfo();
    }, [])
   const handleClosePromo = () => {
        controlDispatch({
            type : "PROMO"
        })
   }
    const handleControlPromo = () => {
        handleClosePromo();
        history.push("/category/Baskets")
        
    }
    return (
        <div className="home">
            <Helmet>
                <meta charSet="UTF-8"/>
                <title>Penyesa Pantry | Deliver groceries to Zimbabwe</title>
                <meta name="description" content="Pantry and home. Shop online from South Africa and anywhere in the world to deliver pantry groceries to Zimbabwe"/>
                <link rel="canonical" href="https://www.pantry.penyesa.co.za" />
            </Helmet>
            {promoState && <Promotions handleClosePromo={handleClosePromo} handleControlPromo={handleControlPromo}/>}
            <TopView/>
            <Category/>
            <Browse/>
            <Footer/>
            <CartButton/>
            
        </div>
    )
}

export default Home;