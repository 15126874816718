import React, {useContext} from 'react'
import {AuthContext} from "../../Context/Auth.Context"
import "../Styles/AuthMessage.scss"

const AuthMessage = () => {
    const {message} = useContext(AuthContext)
    return (
        <div className="authmessage">
            {message}
        </div>
    )
}

export default AuthMessage
