import React, { createContext,useState,useReducer } from 'react'
import {Auth} from "aws-amplify"

export const AuthContext = createContext()

/** States
 * signup
 * confirmsignup
 * resendconfirmation
 * confirmation_resent
 * signedin
 * forgot-pass
 */
const initAuthState = {
    currentAuthState : "signup",
    userInfo : "",
    userEmail : "",
    errorAuthState : {
        name : "",
        status : false,
        message : ""

    }
}




const AuthContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [authState, setAuthState] = useState(initAuthState); 
    const [authMessage, setAuthMessage] = useState({
        status : false,
        message : ""
    })
    const handleNavState = (value) => {
        setAuthState({
            ...authState, 
            currentAuthState : value
        })
    }

    const handleAuthMessage = (message) => {
        setAuthMessage({
            status : true,
            message
        });
        setTimeout(() => {
            setAuthMessage({
                status : false,
                message : ""
            })
        }, 4000);
    }
    const resetError = () => {
        setAuthState({
            ...authState,
            errorAuthState : {
                name : "",
                status : false,
                message : ""
            }
        })
    }
    const handleResendVerification = async (action) => {
        try{
            const userEmail = action.email;
            setIsLoading(true);
            const sendVerificationResponse = await Auth.resendSignUp(userEmail);
            setAuthState({
                ...authState,
                userEmail,
                currentAuthState : "confirmation_resent",
                
            })
            setIsLoading(false);

        }catch(err){
            console.log({err})
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : err.name,
                    status : false,
                    message : err.message
                }
            })
            setIsLoading(false);
        }
    }
    const handleSignUp = async (action) => {
        try{
            const userEmail = action.email;
            setIsLoading(true);
            const signupResponse = await Auth.signUp({
                username : action.email,
                password : action.password,
                attributes: {
                    'custom:first_name' : action.first_name,
                    'custom:last_name' : action.last_name,
                    email : action.email,
                    'custom:mobile_number' : action.mobile_number,
                    'custom:address_line_1' : action.address_line_1,
                    'custom:surburb' : action.surburb,
                    'custom:city' : action.city,
                    'custom:province' : action.province,
                    'custom:post_code' : action.post_code
                }
            })
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : "",
                    status : false,
                    message : ""
                },
                userEmail,
                currentAuthState : "confirmsignup",
                
            })
            setIsLoading(false);
            
            
        }catch(err){
            console.log({err})
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : err.name,
                    status : false,
                    message : err.message
                }
            })
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setIsLoading(false);
        }
    }
    
    const handleSignOut = async () => {
        try{
            await Auth.signOut();
            setAuthState(initAuthState)
            handleAuthMessage("Success! You are now Logged out");
        }catch(err){
            console.log(err);
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : err.name,
                    status : true,
                    message : err.message
                },

            })
        }
    }
    const handleSignIn = async (action) => {
        try{
            setIsLoading(true)
            const currentUser = await Auth.signIn(action.username, action.password)
            const {attributes} = currentUser;
            const fullAddress = `${attributes["custom:address_line_1"]} ${attributes["custom:surburb"]} ${attributes["custom:province"]} ${attributes["custom:post_code"]}`
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : "",
                    status : false,
                    message : ""
                },
                currentAuthState : "signedin",
                userInfo : {
                    first_name : currentUser.attributes["custom:first_name"],
                    last_name : currentUser.attributes["custom:last_name"],
                    Address : currentUser.attributes["custom:address_line_1"],
                    mobile : currentUser.attributes["custom:mobile_number"],
                    email : currentUser.attributes["email"],
                    city : currentUser.attributes["custom:city"],
                    country : currentUser.attributes["custom:province"],
                    fullAddress
                }
                
            })
            setIsLoading(false);
            handleAuthMessage("Success! You are now Logged In")
        }catch(err){
            console.log(err)
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : err.name,
                    status : true,
                    message : err.message
                }
            })
            setIsLoading(false);
        }
    }
    const handleRetrieveSession = async () => {
        try{
            const currentUser = await Auth.currentAuthenticatedUser();
            const {attributes} = currentUser;
            const fullAddress = `${attributes["custom:address_line_1"]} ${attributes["custom:surburb"]} ${attributes["custom:province"]} ${attributes["custom:post_code"]}`
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : "",
                    status : false,
                    message : ""
                },
                currentAuthState : "signedin",
                userInfo : {
                    first_name : currentUser.attributes["custom:first_name"],
                    last_name : currentUser.attributes["custom:last_name"],
                    address : `${currentUser.attributes["custom:address_line_1"]} ${currentUser.attributes["custom:surburb"]}`,
                    mobile : currentUser.attributes["custom:mobile_number"],
                    email : currentUser.attributes["email"],
                    city : currentUser.attributes["custom:city"],
                    country : currentUser.attributes["custom:province"],
                    fullAddress
                }
                
            })
            

        }catch(err){
            setAuthState({
                ...authState,
                currentAuthState : "signup"
            })
        }
    }
    const resetPassword = async (username) => {
        setIsLoading(true)
        try{
            await Auth.forgotPassword(username);
            setIsLoading(false)
        }catch(err){
            console.log(err);
            setAuthState({
                ...authState,
                errorAuthState : {
                    name : err.name,
                    status : true,
                    message : err.message
                }
            })
            setIsLoading(false);
        }

    }

    const resetPasswordConfirm = async () => {

    }


    return (
       <AuthContext.Provider value={{...authState,...authMessage,isLoading,handleSignUp,handleSignIn,
                            resetError,handleNavState,handleResendVerification,handleRetrieveSession,
                            handleSignOut,resetPassword,resetPasswordConfirm
                            }}>
           {props.children}
       </AuthContext.Provider>
    )
}

export default AuthContextProvider
