import React,{createRef,useState,useContext} from 'react'
import {ReactComponent as Pantry} from "../Images/pantry.svg"
import {CartContext} from "../../Context/Cart.Context"
import BackDrop from "../../Shared/Components/BackDrop"
import {useHistory} from "react-router-dom"
import Pdf from "react-to-pdf";
import axios from "axios"



const InvoiceStyle = {
    container : {

        width : "794px",
        height : "1123px",
        backgroundColor : "#ffffff",
        fontFamily  :  "'Raleway', sans-serif",
    },
    headerContainer : {
        width : "100%",
        height : "5rem",
        color : "#4a4110",
        display : "flex",
        backgroundColor : "#e0dfd1",
    },
    headerBrand : {
        flex: "0 0 40%",
        display : "flex",
        alignItems: 'center',
        justifyContent : "center"
        
    },
    brandText : {
        width : "90%",
        display : "flex",
        letterSpacing : ".08em"
    },
    brandName : {
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        paddingLeft: "1rem" ,
    },
    brandTitle : {
        textTransform : "uppercase"
    },
    brandLink : {
        color : "#4a4110"
    },
    headerTab : {
        height : "calc(100% + 2rem)",
        flex: "1",
        clipPath : "polygon(5rem 0%, 100% 0%, 100% 100%, 0% 100%)",
        "-webkit-clip-path" : "polygon(5rem 0%, 100% 0%, 100% 100%, 0% 100%)",
        backgroundColor : "#73736875",
        display : "flex",
        justifyContent : "flex-end"
    },
    headerTab1 : {
        height : "100%",
        width : "90%",
        clipPath : "polygon(5rem 0%, 100% 0%, 100% 100%, 0% 100%)",
        "-webkit-clip-path" : "polygon(5rem 0%, 100% 0%, 100% 100%, 0% 100%)",
        backgroundColor : "#d1b82d73",
        display : "flex",
        justifyContent : "flex-end"
    },
    headerTab2 : {
        height : "100%",
        width : "88%",
        clipPath : "polygon(5rem 0%, 100% 0%, 100% 100%, 0% 100%)",
        "-webkit-clip-path" : "polygon(5rem 0%, 100% 0%, 100% 100%, 0% 100%)",
        backgroundColor : "#4b42119e",
        display : "flex",
        justifyContent : "flex-end"
    },
   
    headerInvoice : {
        width : "75%",
        clipPath : "polygon(5rem 0, 100% 0%, 100% 100%, 0% 100%)",
        "-webkit-clip-path" : "polygon(5rem 0%, 100% 0%, 100% 100%, 0% 100%)",
        backgroundColor : "#DBBA07",
        display : "flex",
        flexDirection : "column",
        alignItems : "flex-end",
        justifyContent: 'center',
        height : "101%",
        paddingRight : "3rem",
    },
    invoiceParticular : {
        
        width : "100%",
        display :  "flex",
        justifyContent : "flex-end",
        letterSpacing : ".13em",
        fontSize : "1.2em"
    },
    invoiceBanner : {
        fontSize : "3.6em",
        letterSpacing : ".1em"
    },
    invoiceLine : {
        width : "80%",
        display : "flex",
        justifyContent : "space-between",
    },
    invoiceNumber : {
        fontSize : "1.3em",
        fontWeight : "700"
    },
    identityContainer : {
        padding: "5rem 2.5rem",
        paddingBottom : "3.5rem",
        display : "grid",
        gridTemplateColumns : "1fr 1fr 1fr",
        justifyContent : "center",
        alignItems : "center",
        gridGap : "2.5rem"
    },
    bankInfo : {
        justifySelf : "flex-end",
        width : "90%",
    },
    bankRow : {
        display : "flex",
        justifyContent : "space-between",
        fontSize : "1.1em",
    },
    paymentLabel : {
        fontWeight : "700",
        color : "#4a4110",
    },
    customerTitle : {
        display : "flex",
        justifyContent : "space-between",
        fontSize : "1.1em",
        width : "100%",
    },
    customerLabel : {
        fontWeight : "700",
        color : "#4a4110",
    },
    productTable : {
        margin : " 0 auto",
        width : '90%',
        backgroundColor : "#f1f1f1",
        font:"1em",
        border:"2px solid #d3d3d345",
        borderCollapse:"collapse"

    },
    rowHeader : {
        backgroundColor : "#b2a55f",
        color : "#312c13",
        borderBottom:"0.8px solid #dddddd",
    },
    rowProductDark : {
        backgroundColor : "lightgrey",
        fontSize : "1.1em",
        color : "#3d3213"
    },
    rowProductLight : {
        color : "#3d3213",
        fontSize : "1.1em",
    },
    rowProductPlace : {
        color : "#fff",
        backgroundColor : "#fff",
    },
    paymentContainer : {
        padding: "1.5rem 3.5rem",
        display: "flex",
        justifyContent : "flex-end",

    },
    totalsInfo : {
        width : "20%",
        padding:".1em"

    },
    totalRow : {
        width : "100%",
        display : "flex",
        justifyContent : "space-between",
        fontSize : "1.2em",
        padding:"0.1em 0em"
    },
    grandTotal : {
        fontSize : "1.3em",
        fontWeight : "700",
        padding: "0.2em 0em",
        borderTop : "1px solid #4a4110",
        marginTop : ".2em",
        fontFamily : "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
    },
    terms :{
        justifyContent : "flex-start",
    },
    termsContainer : {
        width : "60%",
        fontSize : "1em"
    },
    footerContainer : {
        padding: "0rem 3.5rem",
        display: "flex",
        justifyContent : "flex-start",
        color : "#4a4110",
        fontSize : "1.4em"
    },
    paymentValue : {
        fontFamily : "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
    }
    
}
function PayWithOffline({formState,cartProducts,Total}) {
    const ref = createRef();
    const cellDisplay = 10;
    const history = useHistory();
    
    const [payingState, setPayingState] = useState(false)
    const {cartID,cartDispatch} = useContext(CartContext);
    const {shipName,shipSurname,shipMobile,shipEmail,shipAddress,shipCity,shipCountry} = formState;
    const {billName,billSurname,billMobile,billEmail,billAddress,deliveryInfo,paymentMethod} = formState;
    const rowCount = cartProducts.length < cellDisplay ? (cellDisplay - cartProducts.length) : 0
    const blankRows = Array(rowCount).fill(0)
    const vatTotal = (0.15 * Total).toFixed(2);
    const subTotal = (0.85 * Total).toFixed(2);
    ;
    const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = "  ") => {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
          const negativeSign = amount < 0 ? "-" : "";
      
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
      
          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "R1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
      };
      const placeOrder = async (downloadPdf) => {
        setPayingState(true);
        try{
            const response = await axios({
                method : "POST",
                url : "https://rartx1msad.execute-api.eu-west-1.amazonaws.com/MostUpdate/offlineorders/OfflinePurchases",
                data : JSON.stringify({ 
                    Order_ID : `${cartID}`,
                        billName : `${billName} ${billSurname}`,
                        billEmail,
                        billMobile,
                        billAddress,
                        paymentMethod,
                        deliveryIntsruction :deliveryInfo,
                        shipName : `${shipName} ${shipSurname}`,
                        shipAddress : `${shipAddress}, ${shipCity} ${shipCountry}`,
                        shipMobile,
                        shipEmail,
                        products : cartProducts
                })
    
            })
            
            setPayingState(false);
            downloadPdf();
            cartDispatch({type :"ClearCart"})
            history.push("/checkout/success/invoice")
            

        }catch(err){
            console.log(err)
            window.alert("Order Processing Failed, Please Try again. If problem persists please try another payment method or contact us at info@penyesa.co.za")
        }
        
        
        
        
      }
    return (
        <>
            {
                payingState && <BackDrop openState={payingState}/>
            }
            <Pdf targetRef={ref} fileName="invoice.pdf">
                {({toPdf}) => <button className="form-next" onClick={() => placeOrder(toPdf)}>Download Invoice</button>}
            </Pdf>
            <div style={{position: "absolute", opacity : "0", zIndex : "-1"}}>
                <div ref={ref}>
                    <div style={InvoiceStyle.container} >
                    <div style={InvoiceStyle.headerContainer}>
                        <div style={InvoiceStyle.headerBrand}>
                            <div style={InvoiceStyle.brandText}>
                                <Pantry style={{width:"72px",height:"72px"}}/>
                                <div style={InvoiceStyle.brandName}>
                                    <h2 style={InvoiceStyle.brandTitle}>Penyesa Pantry</h2>
                                    <a style={InvoiceStyle.brandLink} href="www.pantry.penyesa.co.za" target="_blank">www.pantry.penyesa.co.za</a>
                                </div>
                            </div>
                        </div>
                        <div style={InvoiceStyle.headerTab}>
                            <div style={InvoiceStyle.headerTab1}>
                                <div style={InvoiceStyle. headerTab2}>
                                    <div style={InvoiceStyle.headerInvoice}>
                                        <h1 style={InvoiceStyle.invoiceBanner}>INVOICE</h1>
                                        <div style={InvoiceStyle.invoiceParticular}>
                                            <div style={InvoiceStyle.invoiceLine}>
                                                <span>Reference No.</span>
                                                <span style={{...InvoiceStyle.invoiceNumber,...InvoiceStyle.paymentValue}}>{cartID}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* --------------Invoice Identity Information--------- */}
                    <div style={InvoiceStyle.identityContainer}>
                        <div style={InvoiceStyle.identityCustomer}>
                            <div style={InvoiceStyle.customerTitle}>
                                <span style={InvoiceStyle.customerLabel}>Invoice To:</span>
                                <span style={InvoiceStyle.customerValue}>{`${billName} ${billSurname}`}</span>
                            </div>
                            <p style={InvoiceStyle.customerText}>{billAddress}</p>
                            <p style={InvoiceStyle.customerText}>{billEmail}</p>
                            <p style={InvoiceStyle.customerText}>{billMobile}</p>
                        </div>
                        <div style={InvoiceStyle.identityCustomer}>
                            <div style={InvoiceStyle.customerTitle}>
                                <span style={InvoiceStyle.customerLabel}>Ship To:</span>
                                <span style={InvoiceStyle.customerValue}>{`${shipName} ${shipSurname}`}</span>
                            </div>
                            <p style={InvoiceStyle.customerText}>{`${shipAddress} ${shipCity}`}</p>
                            <p style={InvoiceStyle.customerText}>{`${shipEmail}`}</p>
                            <p style={InvoiceStyle.customerText}>{`${shipMobile}`}</p>
                        </div>
                        <div style={InvoiceStyle.bankInfo}>
                            <div style={InvoiceStyle.bankRow}>
                                <span style={InvoiceStyle.paymentLabel}>Bank Name</span>
                                <span style={InvoiceStyle.customerValue}>First National bank</span>
                            </div>
                            <div style={InvoiceStyle.bankRow}>
                                <span style={InvoiceStyle.paymentLabel}>Account Name:</span>
                                <span style={InvoiceStyle.customerValue}>PENYESA (PTY) LTD</span>
                            </div>
                            <div style={InvoiceStyle.bankRow}>
                                <span style={InvoiceStyle.paymentLabel}>Account</span>
                                <span style={InvoiceStyle.paymentValue}>62729554156</span>
                            </div>
                        </div>
                    </div>
                    {/* -------------Product Listing----------------- */}
                    <table style={InvoiceStyle.productTable}>
                        <tr style={InvoiceStyle.rowHeader}>
                            <th style={{borderRight:"0.8px solid #dddddd", width:"50%",textAlign:"left",padding:"0.8em 1em"}}>Product</th>
                            <th style={{borderRight:"0.8px solid #dddddd",width:"20%",textAlign:"right",verticalAlign:"top",padding:"0.8em 1em"}}>Price</th>
                            <th style={{borderRight:"0.8px solid #dddddd",width:"10%",verticalAlign:"top",textAlign:"center",padding:"0.8em 1em"}}>Quantity</th>
                            <th style={{width:"20%",padding:"0.8em 1em",textAlign:"right",verticalAlign:"top",padding:"0.8em 1em"}}>Item Total</th>
                        </tr>
                        {
                           cartProducts.map((product,index) => 
                            <tr key={`invoiceProducts-${index}`} style={index % 2 === 0 ? InvoiceStyle.rowProductDark : InvoiceStyle.rowProductLight}>
                                <td style={{border:"0.8px solid #dddddd",padding:"0.8em 1em"}}>{`${product.productBrand} ${product.productTitle} ${product.productSize}`}</td>
                                <td style={{border:"0.8px solid #dddddd",textAlign:"right",padding:"0.8em 1em",...InvoiceStyle.paymentValue}}>R{formatMoney(product.productPrice)}</td>                    
                                <td style={{border:"0.8px solid #dddddd",textAlign:"center",padding:"0.8em 1em"}}>{product.productQty}</td>
                                <td style={{border:"0.8px solid #dddddd",textAlign:"right",padding:"0.8em 1em",fontWeight: "700",...InvoiceStyle.paymentValue}}>R{formatMoney((product.productPrice * product.productQty).toFixed(2))}</td>
                            </tr>
                          )
                        }
                        {
                            blankRows.map((row,index) => 
                                <tr key={`blankR-${index}`}style={InvoiceStyle.rowProductPlace}>
                                    <td style={{border:"0.8px solid #dddddd",borderTop:"none", borderBottom : "none",padding:"0.8em 1em"}}></td>
                                    <td style={{border:"0.8px solid #dddddd",borderTop:"none", borderBottom : "none",textAlign:"right",padding:"0.8em 1em"}}></td>                    
                                    <td style={{border:"0.8px solid #dddddd",borderTop:"none", borderBottom : "none",textAlign:"center",padding:"0.8em 1em"}}></td>
                                    <td style={{border:"0.8px solid #dddddd",borderTop:"none", borderBottom : "none",textAlign:"right",padding:"0.8em 1em",fontWeight: "700"}}></td>
                                </tr>
                                
                            )
                        }
                       
                    </table>
                    {/* ------------- Payment Information ---------- */}
                    <div style={InvoiceStyle.paymentContainer}>
                    
                        <div style={InvoiceStyle.totalsInfo}>
                            <div style={InvoiceStyle.totalRow}>
                                <span style={InvoiceStyle.paymentLabel}>SubTotal</span>
                                <span style={InvoiceStyle.paymentValue}>R{formatMoney(subTotal)}</span>
                            </div>
                            <div style={InvoiceStyle.totalRow}>
                                <span style={InvoiceStyle.paymentLabel}>Tax 15%</span>
                                <span style={InvoiceStyle.paymentValue}>R{formatMoney(vatTotal)}</span>
                            </div>
                            <div style={{...InvoiceStyle.totalRow, ...InvoiceStyle.grandTotal}}>
                                <span style={InvoiceStyle.paymentLabel}>Total</span>
                                <span style={InvoiceStyle.paymentValue}>R{formatMoney(Total)} </span>
                            </div>
                        </div>
                    </div>
                    {/* -----------Terms and conditions -------------- */}
                    <div style={{...InvoiceStyle.paymentContainer, ...InvoiceStyle.terms}}>
                        <div style={InvoiceStyle.termsContainer}>
                            <h4 style={InvoiceStyle.termsHeading}>Note:</h4>
                            <p style={InvoiceStyle.termsText}>Thank you for your order.This confirms that we have received your order which we have placed on hold until we can confirm that payment has been received.Please use the payment reference provided or full name as reference and kindly send the proof of payment to info@penyesa.co.za</p>
                        </div>
                    </div>
                    {/* ------ Footer -------- */}
                    <div style={InvoiceStyle.footerContainer}>
                        <div style={InvoiceStyle.footerIcon}>
                            <h6 style={InvoiceStyle.footerText}>Thank you for your business</h6>
                        </div>
                    </div>
                </div>
            </div>
                
            </div>
        </>
        
    )
}

export default PayWithOffline
