import React from 'react'
import "../Styles/Info.scss"
const Info = () => {
    return (
        <div className="top-info">
            <div className="info-heading">
                Simple & Secure
            </div>
            <div className="info-group">
                <h4 className="info-text">Order</h4>
                <p>&#8650;</p>
            </div>
            <div className="info-group">
                <h4 className="info-text">Pay</h4>
                <p>&#8650;</p>
            </div>
            <div className="info-group">
                <h4 className="info-text">Delivery in Zimbabwe</h4>
            </div>
            <div className="info-terms">
                Ts & Cs apply
            </div>
        </div>
    )
}

export default Info
