import React,{useContext,useState,useRef} from 'react'
import {useHistory} from "react-router-dom"
import "../Styles/OrderSummary.scss"
import { CartContext } from '../../Context/Cart.Context';
import { ProductsContext } from '../../Context/Products.Context';

function OrderSummary({cartProducts}) {
    const Total = cartProducts.reduce((acc,current) => acc + current.productQty * current.productPrice,0).toFixed(2)
    const history = useHistory();
    const [isOpen,setIsOpen] = useState(true)
    const [heightState, setHeightState] = useState("0px")
    const vat = (0.15 * Total).toFixed(2);
    const subTotal = (0.85 * Total).toFixed(2); 
    const {cartDispatch} = useContext(CartContext)
    const {productsDispatch} =useContext(ProductsContext)
    const handleRemoveQuantity = (id, qty) => {
            cartDispatch({type: "RemoveProduct", itemID:id})
            //Remove Cart Label on Product
            productsDispatch({type:"RemoveCartLabel", productID : id})
    }

   
    const orders = cartProducts.map(product => (
        <div className="order-row" key={`ordeSummary-${product.productID}`}>
            <div className="order-image">
                <img  src={product.productImage} alt={product.productTitle}/>
            </div>
            <div className="order-text">
                <h5 className="item-heading">{product.productBrand}</h5>
                <p className="item-text">{product.productTitle}</p>
                <button onClick={() => handleRemoveQuantity(product.productID)} className="item-remove">REMOVE</button>
            </div>
            <div className='order-price'>
                <div className="item-quantity">
                    {product.productQty}
                </div>
                <p className="item-price">{`R${product.productPrice}`}</p>
            </div>
        </div> 
    ))
    const handleToggle = (isValue) => {
        setIsOpen(!isValue);
    }
    return (
        <div className="order">
            <div className="order-nav">
                <h2 className="order-heading">Order Summary</h2>
                <i className={`fas fa-angle-down order-close ${isOpen ? "accordion-rotate" : ""}` }  onClick={() => handleToggle(isOpen)}></i>
            </div>
            <div className={`order-ref ${!isOpen ? "order-ref--active" : ""}`}>
                {
                    orders.length > 0 ? (
                        <>
                            <div className={`order-page`}  >
                                {
                                    orders
                                }
                            </div>
                            <div className="order-totals">
                                <div className="order-subtotals">
                                    <p className="total-text">Subtotal</p>
                                    <p className="total-price">{`R${subTotal}`}</p>
                                </div>
                                <div className="order-subtotals">
                                    <p className="total-text">Vat @ 15%</p>
                                    <p className="total-price">{`R${vat}`}</p>
                                </div>
                                <div className="summary-totals">
                                    <p className="summary-totals-text">Total</p>
                                    <p className="summary-totals-price">{`R${Total}`}</p>
                                </div>
                            </div>
                        </>
                    ) : <>
                            <div className="sidebar-content sidebar-empty">
                                <h1 style={{fontSize: "17px"}} className="sidebar-empty-text">Cart Is Empty</h1>
                            </div>
                            <button  className="order-btn" onClick={() => history.push("/category/Food")}>
                                    <i className="fas fa-shopping-basket"></i>
                                    Continue Shopping
                            </button>
                        </>
                }
            </div>
        </div>
    )
}

export default OrderSummary
