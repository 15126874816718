import React, {useContext,useState} from 'react'
import {ReactComponent as Facebook} from "../Images/Facebook-dark.svg"
import {ReactComponent as Instagram} from "../Images/Instagram-dark.svg"
import {ReactComponent as Pinterest} from "../Images/Pinterest-dark.svg"
import {ReactComponent as Mobile} from "../Images/Mobile.svg"
import {ReactComponent as Email} from "../Images/Email.svg"
import {ReactComponent as CartIcon} from "../Images/Cart.svg"
import {ReactComponent as SignoutIcon} from '../Images/Signout.svg' 
import {ReactComponent as ProfileIcon} from '../Images/Profile.svg' 
import {NavLink} from "react-router-dom"
import {ControlContext} from "../../Context/Control.Context"
import {AuthContext} from "../../Context/Auth.Context"
import "../Styles/Navbar.scss"
import AuthMessage from './AuthMessage'



const Navbar = () => {
    const {controlDispatch} = useContext(ControlContext)
    const {userInfo,status, currentAuthState,handleSignOut} = useContext(AuthContext)
    const [accountState, setAccountState] = useState(false)
    const handleCartControl = () => {
        controlDispatch({type : "CART"});
    }
    const signOut = () => {
        handleSignOut();
        setAccountState(false);
    }
    return (
        <div className="nav">
            <div className="nav-contact">
                <div className="nav-mobile">
                    <Mobile className="nav-icon"/>
                    <span className='nav-text'>
                    <a href="tel:+27658087492">
                        +27 65 808 7492
                    </a>
                    </span>
                </div>
                <div className="nav-email">
                    <Email className="nav-icon"/>
                    <span className='nav-text'>
                    <a href="mailto:info@penyesa.co.za?bcc=developer20@penyesa.co.za&subject=Enquiry&body=Greetings%20Penyesa%2C%0D%0A%0D%0AI%20would%20like%20to%20make%20an%20enquiry%20regarding%20%3A%0D%0A%0D%0A%0D%0A%0D%0A">
                        info@penyesa.co.za
                    </a>
                    </span>
                </div>
            </div>
            <div className="nav-social">
                <a href="https://www.facebook.com/PenyesaSA/"><Facebook className="nav-icon" /></a>
                <a href="https://www.instagram.com/PenyesaSA/"><Instagram className="nav-icon"/></a>
                <a href="http://www.pinterest.com/PenyesaSA"><Pinterest className="nav-icon"/></a>
                
                
            </div>
            <div className="nav-navigation">
                <NavLink className="nav-link" to="/">
                    Home
                </NavLink>
                <NavLink className="nav-link" to="/category/Food">
                    Shop
                </NavLink>
                {/* <NavLink className="nav-link" to="/">
                    Home
                </NavLink> */}
                <div className="nav-icon">
                    <CartIcon onClick={handleCartControl} className="nav-icon--cart"/>
                    <div className="nav-icon--label"></div>
                </div>
                {
                    currentAuthState === "signedin" && <div className="nav-account" 
                        onClick={() => setAccountState(true)}>{`${userInfo.first_name.slice(0,1)}${userInfo.last_name.slice(0,1)}`}</div>

                }
                {
                    accountState === true && <div className="nav-dropdown"
                        onMouseLeave={() => setAccountState(false)}>
                            <div className="nav-dropItem">
                            {<ProfileIcon/>} <span className="account-text">Profile </span>
                            </div>
                            <div className="nav-dropItem" onClick={signOut}>
                            {<SignoutIcon/>} <span className="account-text">Sign out </span>
                            </div>
                        </div>
                }
                
            </div>
            {
                status === true && <AuthMessage/>
            }
        </div>
    )
}

export default Navbar
