import React, {useState} from 'react'
import "../Styles/Payment.scss"
import {ReactComponent as PaypalLogo} from "../Images/PaypalLogo.svg"
import {ReactComponent as PayfastLogo} from "../Images/PayfastLogo.svg"
import {ReactComponent as TransferLogo} from "../Images/BankTransLogo.svg"
import {useHistory} from "react-router-dom"
import FormError from "./errorMessage"

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const mobileRegex = RegExp(
    /^\+(?:[0-9] ?){10,14}[0-9]$/
) 

const formValid = (formErrorData) => {
    let valid = true;
    // validate form errors being empty
    Object.values(formErrorData).forEach(val => {
        val.length > 0 && (valid = false);
      });
  
    return valid;
  };
const Payment = ({formState, inputChange,initialState,setInitialState}) => {
    let history = useHistory();
    const [payErrors,setPayErrors] = useState(initialState)
    const [isInitial, setisInitial] = useState(true)
    const handleInputChange = (e) =>{
        console.log(e)
        const {name,value} = e.target;
        inputChange(name, value)
        let tempVal = "";
        switch(name) {
            case "billEmail":
                tempVal = emailRegex.test(value) ? "" : "Invalid email address"
                setPayErrors({
                    ...payErrors, billEmail : tempVal
                })
                break;
            case "billMobile":
                    tempVal = mobileRegex.test(value)  ? "" : "Invalid International mobile number";
                setPayErrors({
                    ...payErrors, billMobile : tempVal
                })
                break;
            case "billName":
                tempVal = value.length < 1 ? "Field Is required" : ""
                setPayErrors({
                    ...payErrors, billName : tempVal
                })
                break;
            case "billSurname":
                tempVal = value.length < 1 ? "Field Is required" : ""
                setPayErrors({
                    ...payErrors, billSurname : tempVal
                })
                break;
            case "paymentMethod" : 
            tempVal = value.checked === 0 ? "Field Is required" : ""
                setPayErrors({
                    ...payErrors,paymentMethod : tempVal
                })
                break;
            case "billAddress" : 
            tempVal = value.length < 1 ? "Field Is required" : ""
                setPayErrors({
                    ...payErrors, billAddress : tempVal
                })
                break;
            default : 
            setPayErrors({
                ...payErrors
            })
        }
    }
    
    const handleOnSubmit = (e) =>{
        e.preventDefault()
        setisInitial(false);

        if (formValid(payErrors)) {
            setInitialState(payErrors)
            history.push("/checkout/review")
          } else {
            console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
          }
    }
    const PhoneInputSet = (v) => {
        console.log(v)
    }
    return (
        <div className="payment">
            <h1 className="payment-heading">Payment Information</h1>
            <form className="payment-form" id="payment" noValidate onSubmit={handleOnSubmit}>
                {
                    !isInitial && payErrors.paymentMethod.length > 0 && <FormError error={payErrors.paymentMethod}/>
                }
                <div className="method-group">
                    <div className= "method-container">
                        <PayfastLogo className="method-provider"/>
                        <input type="radio" className="method-radio"
                            onChange={handleInputChange} 
                            required value="Payfast" name="paymentMethod"/>
                    </div>
                    <div className ="method-container">
                        <PaypalLogo className="method-provider"/>
                        <input type="radio" className="method-radio" 
                            onChange={handleInputChange}
                            required value="Paypal" name="paymentMethod"/>
                    </div>
                    <div className ="method-container">
                        <TransferLogo className="method-provider"/>
                        <input type="radio" className="method-radio" 
                            onChange={handleInputChange}
                            required value="Transfer" name="paymentMethod"/>
                    </div>
                    
                </div>
                
                <div className="details-group">
                    <div className="details-input">
                        <input onChange={handleInputChange} 
                            value={formState.billName}
                            className="formInput" required
                            type="text" name="billName" id="billName"/>
                        {
                            !isInitial && payErrors.billName.length > 0 && <FormError error={payErrors.billName}/>
                        }
                        <label className="formLabel" htmlFor="billName">First Name*</label>
                    </div>
                    
                    <div className="details-input">
                         <input onChange={handleInputChange} className="formInput" 
                            value={formState.billSurname}
                            required type="text" name="billSurname" id="billSurname"/>
                            {
                                !isInitial && payErrors.billSurname.length > 0 && <FormError error={payErrors.billSurname}/>
                            }
                         <label className="formLabel" htmlFor="billSurname">Last Name*</label>
                    </div>
                   
                    <div className="details-row">
                        <div className="details-input ">
                            <input onChange={handleInputChange} className="formInput" 
                                value={formState.billEmail}
                                required type="email" name="billEmail" id="billEmail"/>
                                {
                                    !isInitial && payErrors.billEmail.length > 0 && <FormError error={payErrors.billEmail}/>
                                }
                            <label className="formLabel" htmlFor="billEmail">Email*</label>
                        </div>
                        <div className="details-input ">
                            <input onChange={handleInputChange} className="formInput" 
                                value={formState.billMobile}
                                required type="tel" name="billMobile" id="billMobile"/>
                                {
                                    !isInitial && payErrors.billMobile.length > 0 && <FormError error={payErrors.billMobile}/>
                                }
                            <label className="formLabel" htmlFor="billMobile">Phone *</label>
                        </div>
                        
                    </div>

                    <div className="details-input">
                        <input onChange={handleInputChange} className="formInput" 
                            value={formState.billAddress}
                            required type="text" name="billAddress" id="billAddress"/>
                            {
                                !isInitial && payErrors.billAddress.length > 0 && <FormError error={payErrors.billAddress}/>
                            }
                        <label className="formLabel" htmlFor="billAddress">Address*</label>
                    </div>

                    
                </div>
                <div className="button-group">
                    <button className="form-back" name="back" onClick={() => history.push("/checkout/shipping")}><span className="form-back-text">&#8592;</span>back</button>
                    <button className="form-next" name="next" type="submit" form="payment">next</button>
                </div>
            </form>
        </div>
    )
}

export default Payment
