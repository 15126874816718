import React, {useState,useContext} from 'react'
import {ReactComponent as CartIcon} from '../Images/CartIcon.svg'
import {ReactComponent as CartInfo} from "../Images/CartInfo.svg"
import {CartContext} from "../../Context/Cart.Context"
import { ProductsContext} from "../../Context/Products.Context"
import {ControlContext} from "../../Context/Control.Context"
import QuickViewModal from "./QuickViewModal"


import "../Styles/Card.scss"

const Card = ({product}) => {
    const {id,title,brand,price,size,url,isInCart,stock_Qty,display_Stock} = product
    const {cartProducts,cartDispatch} = useContext(CartContext)
    const {controlDispatch} = useContext(ControlContext)
    const {isLoading, productsDispatch} = useContext(ProductsContext)
    const [isView, setIsView] = useState(false)
    const [isModal, setIsModal] = useState(false)

    const currentProduct= cartProducts.find(item => {
        return item.productID === id
    })
    const quantity = currentProduct ? currentProduct.productQty : 0;

    //Add Quantities to Cart
    const handleRemoveQuantity = (id, qty) => {
        if(qty > 1){
          
            cartDispatch({type : "Subtract", itemID : id})
            
        }else{
            cartDispatch({type: "RemoveProduct", itemID:id})
            //Remove Cart Label on Product
            productsDispatch({type:"RemoveCartLabel", productID : id})
        }
        //Add Stock Total
        productsDispatch({type : "AddStockQty", productID: id})
    }
    //Add to Cart
    const handleAddToCart = () => {
        cartDispatch({
            type : "AddProduct",
            itemID : id,
            itemBrand : brand,
            itemTitle : title,
            itemPrice : price,
            itemSize : size,
            itemURL : url,
            itemQty : 1,
            stock_Qty,display_Stock

        })
        //Add Cart Label on Product
        productsDispatch({type:"AddCartLabel", productID : id})
        //Subtract Stock Total
        productsDispatch({type : "RemoveStockQty", productID: id})
    }
    const handleControlCart = () => {
        controlDispatch({type: "CART"})
    }
    /**const handleQuickViewOff = () =>{
        setIsView(false);
    }*/
    const handleModalView = (value) => {
        setIsModal(value);
    }
    return (
        <>
            <div className="card" onMouseLeave={() => setIsView(false)}>
                {
                    stock_Qty <= 0 && <div className="hover-card hover-card--stock">
                        <div onClick={() => handleModalView(true)} className="hover-info">
                                Out of Stock
                            </div>
                    </div>
                }
                {
                    isView && (
                        <div onMouseLeave={() => setIsView(false)} className="hover-card">
                            <button onClick={() => handleModalView(true)} className="hover-info">
                                Quick View
                            </button>
                            <div className="hover-price">
                                <span className="hover-price-label">R</span>{`${price}`}
                            </div>
                        </div>
                    )
                }
                {
                    isInCart && <div onClick={handleControlCart} className="card-cartLabel">
                        In cart
                    </div>

                }
                {
                    isInCart && <div onClick={handleControlCart} className="card-cartLabel card-cartLabel-quantity">
                        {quantity}
                    </div>

                }
                {   !isLoading &&
                    <>
                        <div className="card-text">
                            <h1 className="card-header">{brand}</h1>
                            <h4 className="card-title">{title}</h4>
                            <h5 className="card-size">{size}</h5>
                        </div>
                        <div onMouseEnter={() =>  setIsView(true)} className="card-image">
                            <img className="card-image--png"src={url} alt={title}/>
                        </div>
                        <div className="card-control">
                            <div className={`card-price ${isView && "card-hidden"}`}>
                                <span style={{fontSize:"23px"}}>R</span>{price}
                            </div>
                            <div className="card-cart">
                                <button onClick={() => handleModalView(true)} className="cart-btn cart-btn--info">
                                        <span className="cart-add">Info</span>
                                        <CartInfo className="cart-icon"/>
                                        
                                </button>
                                {
                                    stock_Qty > 0 && (
                                        <button onClick={() => handleAddToCart()} className="cart-btn " disabled={display_Stock <= 0 ? true : false}>
                                            <span className="cart-add">Add</span>
                                            <CartIcon className="cart-icon"/>
                                        </button>
                                    )
                                }
                                
                                
                                

                            </div>
                        </div>
                    </>
                }
            </div>
            {
                isModal && <QuickViewModal 
                            showModal={handleModalView}
                            addCart={handleAddToCart}
                            subtractCart={handleRemoveQuantity} 
                            product={{...product, quantity}}
                            />
            }
        </>
    )
}

export default Card
