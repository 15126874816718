import { input } from 'aws-amplify'
import React, {useState,useContext} from 'react'
import {Link, useHistory} from "react-router-dom"
import {AuthContext} from "../../Context/Auth.Context"
import Loader from "../../Shared/Components/Loader"
import "../Styles/Login.scss"
import FormError from './FormError';



const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const formValid = (loginErrorData) => {
    let valid = true;
    // validate form errors being empty
    Object.values(loginErrorData).forEach(val => {
        val.length > 0 && (valid = false);
      });
  
    return valid;
  };

const Login = () => {
    let history = useHistory();
    const {errorAuthState,handleNavState,resetError,isLoading,handleSignIn} = useContext(AuthContext)
    const [inputState, setInputState] = useState({
        loginEmail : "",
        loginPassword : ""
    });
    const [loginError,setLoginError] = useState({
        loginEmail : "Email is required",
        loginPassword : "Password is required"
    });
    const [isInitial, setisInitial] = useState(true)
    const handleInputChange = (event) => {
        //Validate Error posibility on value change
        const {name, value} = event.target;
        setInputState({
            ...inputState, 
            [name] : value
        })
        let tempVal = "";
        switch(name){
            case "loginEmail" :
                if(value.length > 0){
                    resetError();
                    tempVal = emailRegex.test(value) ? "" : "Provided Email Invalid"
                }
                setLoginError({
                    ...loginError, loginEmail : tempVal
                })
            break;
            case "loginPassword" : 
                tempVal = value.length < 1 ? "Password Is required" : ""
                setLoginError({
                    ...loginError, loginPassword : tempVal
                })
            break;
            default : 
                setLoginError({
                    ...loginError
                })
            break;
        } 

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setisInitial(false)
        if (formValid(loginError)) {
            handleSignIn({
                username : inputState.loginEmail.toLowerCase(),
                password : inputState.loginPassword
            })
            setisInitial(true)
          } else {
            console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
          }
    }
    const handleForgotPassword = () => {
        handleNavState("forgot-pass");
        history.push("/account/forgot_pass");
    }
    return (
        <div className="login">
            <div className="login-header">
                <span className="login-title">Login</span>
            </div>
            <form id="LoginForm" className="login-form" onSubmit={handleSubmit} noValidate>
                <div className="login-group">
                    <label htmlFor="loginEmail" className="login-label">Your Email</label>
                    <input name="loginEmail" className="login-input" type="email" onChange={handleInputChange}/>


                    <label htmlFor="loginPassword" className="login-label">Password</label>
                    <input name="loginPassword" className="login-input" type="password" onChange={handleInputChange}/>
                    
                </div>

               <div className="login-group-btn">
                   {
                       isLoading && <Loader/>
                   }
                    <button className="login-submit login-save" name="submit" type="submit" form="LoginForm">Login</button>
               </div>
               <div to="/account/forgot-password" onClick={handleForgotPassword}><h1 className="login-forgotPass">Forgot password?</h1></div>
               <h1 className={`login-account-cta`}>
                {`Don't have a Penyesa account? `}
                    {
                        <Link className="login-account-link" to="/account/signup">Register</Link>
                } 
                </h1>
            </form>
            <h1 className={`login-cta`}>{
                isInitial ? (
                    <>
                        {errorAuthState.status !== true &&  `REGISTER or LOGIN to shop securely`}
                        {errorAuthState.name === "NotAuthorizedException" && <FormError error={errorAuthState.message}/>}
                        {errorAuthState.name === "UserNotFoundException" && <FormError error={errorAuthState.message}/>}
                        
                    </>
                   
                )   : (
                  <>
                    {errorAuthState.name === "UserNotFoundException" && <FormError error={errorAuthState.message}/>}
                    {errorAuthState.name === "UserNotFoundException" && <FormError error={errorAuthState.message}/>}
                    {loginError.loginEmail.length > 0 && <FormError error={loginError.loginEmail}/>}
                    {loginError.loginPassword.length > 0 && <FormError error={loginError.loginPassword}/>}
                  </>

                )
                }
            </h1>

        </div>
    )
}

export default Login
