import React, { useContext } from 'react'
import "../Styles/Browse.scss"
import {ReactComponent as Arrow} from "../Images/Arrow.svg"
import Card from "../../Shared/Components/Card"
import {ProductsContext} from "../../Context/Products.Context"


const Browse = () => {
    const {products} = useContext(ProductsContext);
    
    const displayProducts = products.filter(item => {
        return (item.isBrowse === "TRUE" || item.isBrowse === true) && item.stock_Qty > 0;
    })
    
    return (
        <div className="browse">
            <div className="browse-heading">
                Browse family favourites
            </div>
            <div className="browse-slider">
                {
                    displayProducts.map(product => <Card key={`FirstBrowse-${product.id}`} product={{...product}}/>)
                }
            </div>
            {/* <div className="control-container">
                <Arrow className="control-arrow control-arrow--left"/>
                <Arrow className="control-arrow control-arrow--right"/>
            </div> */}
        </div>
    )
}

export default Browse
