import React from 'react'
import {Link} from 'react-router-dom'
import Loader from "../../Shared/Components/Loader"
import "../Styles/CompleteSignup.scss"

const CompleteSignup = () => {
    return (
        <div className="completesignup">
            <div className="completesignup-container">
                <h1 className="completesignup-title">
                    Account registration complete
                </h1>
                <p className="completesignup-p">
                You have successfully verified your email. Your Penyesa account has been successfully set up, 
                sign into your account for secure and quick shopping.
                </p>
                <div className="signup-button-group">
                    <Link className="completesignup-btn" to="/" >Sign in <span className="completesignup-arrow">&#8603;</span></Link>     
                </div>
            </div>
            
        </div>
    )
}

export default CompleteSignup
