import React, { useContext } from "react";

import "../Styles/Promotions.scss";
import ChristmasPromo from "../Images/BackgroundFill.png";
import MothersDayPromo from "../Images/BackgroundFillMothersDayOrange.png";
import FathersDay from "../Images/fathersDay.png";

const Promotions = ({ handleControlPromo, handleClosePromo }) => {
  return (
    <div className="promotions">
      <img className="promotions-img" src={ChristmasPromo}></img>
      <div className="promotions-close" onClick={handleClosePromo}>
        <i className="far fa-window-close"></i>
      </div>
      <div className="promotions-shop" onClick={handleControlPromo}>
        SHOP NOW
      </div>
    </div>
  );
};

export default Promotions;
