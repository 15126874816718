import React, {createContext, useReducer, useEffect}from 'react'
import axios from "axios"

export const ProductsContext = createContext()
const initialState = {
    products :  [
        {
            id : "Temp-01",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : true,
        },
        {
            id : "Temp-02",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : true,
            category : "Temp"
        },
        {
            id : "Temp-03",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-04",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-05",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        
        {
            id : "Temp-06",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-07",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-08",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-09",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        
        {
            id : "bask-Ess01",
            brand : "Penyesa Basket",
            title : "Essentials",
            size : "",
            price : 1000,
            stock_Qty : 100,
            display_Stock : 10,
            url : "https://penyesa-pictures.s3.af-south-1.amazonaws.com/essentials.png",
            category : "Baskets",
            description : "3 x 2kg Rice  2 x 2kg Sugar  2 x 2.5kg Self Raising Flour  1 x 2L Cooking Oil  2 x 1kg Sugar Beans  2 x Soups  2 x Pilchards  1 x Joko Tea  1 x 1kg Cremora  2 x Soya Mince  1 x Washing powder  2 Bars Washing Soap  1 x 100mL Toothpaste",
            isBrowse : false,
    
        },
        {
            id : "bask-Ess02",
            brand : "Penyesa Basket",
            title : "Standard",
            size : "",
            stock_Qty : 100,
            display_Stock : 10,
            price : 1450,
            url : "https://penyesa-pictures.s3.af-south-1.amazonaws.com/standard.png",
            category : "Baskets",
            description : "1 x 5kg Basmati Rice 2 x 2kg Sugar 2 x 2.5kg s/Raising Flour  1 x 2L Cooking Oil  2 X 1kg Sugar Beans  4 x bars Washing Soap  2 x Tooth paste  1 x 1kg Cremora  1 x 2kg Washing Powder  4 x Soups  4 x Soya Mince  2 x Baked Beans  2 x Pilchards  2 x Corned Meat  2 x 500g Spaghetti  2 x 500g Macaroni 2 x Biscuits 1 x Joko Tea",
            isBrowse : false,
    
        },
        {
            id : "bask-Ess03",
            brand : "Penyesa Basket",
            title : "Deluxe",
            size : "",
            price : 1950,
            stock_Qty : 100,
            display_Stock : 10,
            url : "https://penyesa-pictures.s3.af-south-1.amazonaws.com/deluxe.png",
            category : "Baskets",
            description : "1 x 5kg Rice  2 x 2kg Sugar  2 x 2.5kg Self Raising Flour  2 x 2L Cooking Oil  2 x 1kg Sugar Beans  4 x bars Washing Soap  2 x Toothpaste  4 x Soups  4 x Soya Mince  2 x Baked Beans  1 x Vaseline jar  2 x Roll on  2 x Pilchards  2 x Corned Meat  6 x 500g Spaghetti  1 x 3kg Macaroni  2 x Biscuits  1 x 750g Mayonnaise  2 x Packet of Sweets  2 x Lays  1 x Joko Tea  1 x 1kg Cremora1 x tin Ricoffy1 x 2kg Washing Powder",
            isBrowse : false,
    
        }
    ],
    isLoading : true,
}



const reducer = (state, action) => {
    switch(action.type){
        case "Success" : 
            let products = [...action.payload] 
            products = products.filter(product => product.brand !== "Temp")
            
            const outOfStock = products.filter(product => product.stock_Qty <= 0)
            const inStock = products.filter(product => product.stock_Qty > 0)
            products = [...inStock,...outOfStock] 
            return {
                isLoading : false,
                products : products
            }
        case "Error" : {
            console.log(action.Err)
            alert("There was an Error Loading Products Please Refresh Or Contact Info@penyesa.co.za")
            return{
                ...state,
                isLoading : false
            }
        }
        case "AddCartLabel" : 
            const updatedAddChipProductData = state.products.map(product => (
                product.id === action.productID ? {...product, isInCart : true} : product
            ))
            return{
                ...state, 
                products : [...updatedAddChipProductData]
            }
        case "RemoveCartLabel" : 
            const updatedRemoveChipProductData = state.products.map(product => (
                product.id === action.productID ? {...product, isInCart : false} : product
            ))
        
            return{
                ...state, 
                products : [...updatedRemoveChipProductData]
            }
        case "AddStockQty" : 
            const updatedAddStockQty = state.products.map(product => (
                product.id === action.productID ? {...product , display_Stock : product.display_Stock + 1 } : product
            ))
            return {
                ...state,
                products : [...updatedAddStockQty]
            }
        case "RemoveStockQty" : 
               const updatedRemoveStockQty = state.products.map(product => (
                   product.id === action.productID ? {...product, display_Stock : product.display_Stock - 1} : product
               ))
        return {
                ...state,
                products : [...updatedRemoveStockQty]
            }
        case "LOAD" : 
        return {
            ...state,
            isLoading : action.loadState
        }
        default :
            return state
    }
}


export function ProductsContextProvider(props) {
    
    const [productDataState, productsDispatch] = useReducer(reducer, initialState)
        const getProducts = async () => {
        let products = [...productDataState.products]
        /**https://rartx1msad.execute-api.eu-west-1.amazonaws.com/Dev/products/ProductsAlpha */
        const productURL = process.env.NODE_ENV === "development" ? "" : "https://rartx1msad.execute-api.eu-west-1.amazonaws.com/Dev/products/ProductsAlpha"
        
        try {
            const response = axios.get(productURL)
            const results = (await response).data;
            results.map(result => products.push({
                id : result.id,
                brand : result.brand,
                title : result.title,
                size : result.size,
                price : result.price,
                url : `https://penyesa-pictures.s3.af-south-1.amazonaws.com/${result.url}.png`,
                isBrowse : result.isBrowse,
                category : result.category,
                stock_Qty : result.stock_Qty, 
                display_Stock : result.stock_Qty,
                description : result.description 
            }))

            productsDispatch({type : "Success", payload : products})


        } catch (error) {
            productsDispatch({type : "Error", Err : error})
        }
    }
    useEffect(() =>{
        getProducts();
    }, [])
    return (
        <ProductsContext.Provider value={{...productDataState, productsDispatch}}>
            {props.children}
        </ProductsContext.Provider>
    )
}


