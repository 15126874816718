import React, { useContext,useEffect } from 'react'
import {ControlContext} from "../../Context/Control.Context"
import {CartContext} from "../../Context/Cart.Context"
import axios from 'axios';

const PayWithPayfast = ({formState,cartProducts,Total}) => {
    const {cartID} = useContext(CartContext);
    const {isPaying} = useContext(ControlContext);

    const {shipName,shipSurname,shipMobile,shipEmail,shipAddress,shipCity,shipCountry} = formState;
    const {billName,billSurname,billMobile,billEmail,billAddress,deliveryInfo,paymentMethod} = formState;
    const handlePostData = async () => {
        try{
            const response = await axios({
                method : "post",
                url : "https://rartx1msad.execute-api.eu-west-1.amazonaws.com/Dev/pendingorders",
                headers : {
                    
                    "content-type":"application/json"
                },
                data : {
                    Order_ID : `${cartID}`,
                    billName : `${billName} ${billSurname}`,
                    billEmail,
                    billMobile,
                    billAddress,
                    paymentMethod,
                    deliveryIntsruction :deliveryInfo,
                    shipName : `${shipName} ${shipSurname}`,
                    shipAddress : `${shipAddress}, ${shipCity} ${shipCountry}`,
                    shipMobile,
                    shipEmail,
                    products : cartProducts
                    

                }  
            })
            console.log(response.data)
        }catch(err){
            console.log(err.toJSON());
            localStorage.setItem(`${cartID}`, JSON.stringify(
                {
                    billName : `${billName} ${billSurname}`,
                    billEmail,
                    billMobile,
                    billAddress,
                    paymentMethod,
                    deliveryInfo,
                    shipName : `${shipName} ${shipSurname}`,
                    shipAddress : `${shipAddress}, ${shipCity} ${shipCountry}`,
                    shipMobile,
                    products : cartProducts.map(product => {
                        return {
                            ...product
                        }
                    })
                }
            ))
        }
    }
    useEffect(()=>{
        handlePostData();
    },[])
    const handleSubmitPay = (event) => {
        localStorage.removeItem(cartID)
        event.preventDefault();
        event.target.submit()
    }
    return (
        <div>
            <>
                <form onSubmit={handleSubmitPay} action="https://www.payfast.co.za/eng/process" method="POST" id="myForm"> 
                    <input type="hidden" readOnly name="merchant_id" value="15055294"/>
                    <input type="hidden" readOnly name="merchant_key" value="mu9k8pig0pdr7"/>
                    <input type="hidden" readOnly name="return_url" value={`https://pantry.penyesa.co.za/success/${cartID}`}/>
                    <input type="hidden" readOnly name="cancel_url" value={`https://pantry.penyesa.co.za/`}/>
                    <input type="hidden" readOnly name="name_first" value={billName}/>
                    <input type="hidden" readOnly name="name_last" value={billSurname}/>
                    <input type="hidden" readOnly name="email_address" value={billEmail}/>
                    <input type="hidden" readOnly name="m_payment_id" value={cartID}/>
                    <input type="hidden" readOnly name="amount" value={Total}/>
                    <input type="hidden" readOnly name="item_name" value="PenyesaGroceryPackage"/>
                    <input type="hidden" readOnly name="item_description" value={`${cartProducts.map(product => product.productTitle )} `}/>
                    <input type="hidden" readOnly name="email_confirmation" value="1"/>
                    <input type="hidden" readOnly name="confirmation_address" value="info@penyesa.co.za"/>
                </form>
                <button className="form-next" type="submit" form="myForm">
                    PAY
                </button>
                {/* <ReviewBackdrop openState={isPaying}/> */}
        </>
            
        </div>
    )
}

export default PayWithPayfast
