import React, { useState,useEffect } from 'react'
import "../Styles/Shipment.scss"
import {useHistory} from "react-router-dom"
import FormError from './errorMessage';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const mobileRegex = RegExp(
    /^((?:\+263|\+27|263|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/i
)

const formValid = (formErrorData) => {
    let valid = true;
    // validate form errors being empty
    Object.values(formErrorData).forEach(val => {
        val.length > 0 && (valid = false);
      });
  
    return valid;
  };

const Shipment = ({formState, inputChange,initialState,setInitialState}) => {
    let history = useHistory();
    const [shipErrors,setShipErrors] = useState(initialState)
    const [isInitial, setisInitial] = useState(true)

    useEffect(() => {
        setShipErrors(initialState)
    },[initialState])
    const handleInputChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target
        inputChange(name, value);
        let tempVal = "";
        switch(name) {
            case "shipEmail":
                if(value.length > 0){
                    tempVal = emailRegex.test(value) ? "" : "Optional Email Invalid"
                }
                setShipErrors({
                    ...shipErrors, shipEmail : tempVal
                })
                break;
            case "shipMobile":
                tempVal = mobileRegex.test(value) ? "" : "Invalid mobile number";
                setShipErrors({
                    ...shipErrors, shipMobile : tempVal
                })
                break;
            case "shipName":
                tempVal = value.length < 1 ? "Field Is required" : ""
                setShipErrors({
                    ...shipErrors, shipName : tempVal
                })
                break;
            case "shipSurname":
                tempVal = value.length < 1 ? "Field Is required" : ""
                setShipErrors({
                    ...shipErrors, shipSurname : tempVal
                })
                break;
            case "shipCity" : 
            tempVal = value.length < 1 ? "Field Is required" : ""
                setShipErrors({
                    ...shipErrors,shipCity : tempVal
                })
                break;
            case "shipCountry" : 
            tempVal = value.length < 1 ? "Field Is required" : ""
                setShipErrors({
                    ...shipErrors, shipCountry : tempVal
                })
                break;
            case "shipAddress" : 
            tempVal = value.length < 1 ? "Field Is required" : ""
                setShipErrors({
                    ...shipErrors, shipAddress : tempVal
                })
                break;
            default : 
            setShipErrors({
                ...shipErrors
            })
        }
       
    }
    
    const handleOnSubmit = (e) =>{
        e.preventDefault()
        setisInitial(false)

        if (formValid(shipErrors)) {
            setInitialState(shipErrors)
            history.push("/checkout/payment")
          } else {
            console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
          }

        
        
    }
    return (
        <div className="shipment" >
            <h1 className="shipment-heading">Shipping Information</h1>
            <form className="shipment-form" id="shipment" onSubmit={handleOnSubmit} noValidate>
                <div className="details-group">
                    <div className="details-row">
                        <div className="details-input">
                            <input 
                                onChange={handleInputChange} 
                                value={formState.shipName} 
                                className="formInput" required 
                                type="text" name="shipName" id="shipName"/>
                                {
                                    !isInitial && shipErrors.shipName.length > 0 && <FormError error={shipErrors.shipName}/>
                                }
                            <label className="formLabel" htmlFor="shipName">First Name</label>
                        </div>
                        <div className="details-input">
                            <input 
                                onChange={handleInputChange}
                                value={formState.shipSurname}
                                className="formInput" required type="text" 
                                name="shipSurname" id="shipSurname"/>
                                {
                                    !isInitial && shipErrors.shipSurname.length > 0 && <FormError error={shipErrors.shipSurname}/>
                                }
                            <label className="formLabel" htmlFor="shipSurname">Last Name</label>
                        </div>
                    </div>
                   
                    <div className="details-row">
                        <div className="details-input ">
                            <input 
                                onChange={handleInputChange}
                                value={formState.shipMobile} 
                                className="formInput" required type="text" 
                                name="shipMobile" id="shipMobile"/>
                                {
                                    !isInitial && shipErrors.shipMobile.length > 0 && <FormError error={shipErrors.shipMobile}/>
                                }
                            <label className="formLabel" htmlFor="shipMobile">Phone</label>
                        </div>
                        <div className="details-input ">
                            <input 
                                onChange={handleInputChange} 
                                value={formState.shipEmail} 
                                className="formInput" required type="email" 
                                name="shipEmail" id="shipEmail"/>
                            <label className="formLabel" htmlFor="shipEmail">Email</label>
                                {
                                    !isInitial && shipErrors.shipEmail.length > 0 && <FormError error={shipErrors.shipEmail}/>
                                }
                        </div>
                    </div>

                    <div className="details-input">
                        <input 
                            onChange={handleInputChange} 
                            value={formState.shipAddress}
                            className="formInput" required type="text" 
                            name="shipAddress" id="shipAddress"/>
                            {
                                !isInitial && shipErrors.shipAddress.length > 0 && <FormError error={shipErrors.shipAddress}/>
                            }
                        <label className="formLabel" htmlFor="shipAddress">Address</label>
                    </div>
                    <div className="details-row">
                        <div className="details-input ">
                            <input 
                                onChange={handleInputChange}
                                value={formState.shipCity}
                                className="formInput" required type="text" 
                                name="shipCity" id="shipCity"/>
                               {
                                    !isInitial && shipErrors.shipCity.length > 0 && <FormError error={shipErrors.shipCity}/>
                                }
                            <label className="formLabel" htmlFor="shipCity">City</label>
                        </div>
                        <div className="details-input ">
                            <input 
                                onChange={handleInputChange} 
                                value={formState.shipCountry}
                                className="formInput" required type="text" 
                                name="shipCountry" id="shipCountry"/>
                               {
                                    !isInitial && shipErrors.shipCountry.length > 0 && <FormError error={shipErrors.shipCountry}/>
                                }
                            <label className="formLabel" htmlFor="shipCountry">Country</label>
                        </div>
                                                
                    </div>
                    <div className="details-input ">
                            <textarea required
                                onChange={handleInputChange} 
                                value={formState.deliveryInfo}
                                className="formInput" style={{height:"2.5rem"}} 
                                 type="text" name="deliveryInfo" id="deliveryInfo"/>
                            <label className="formLabel" htmlFor="deliveryInfo">Optional Delivery Information</label>
                        </div>
                    
                </div>
                <div className="button-group">
                    <button className="form-back" name="back" onClick={() => history.push("/")}><span className="form-back-text">&#8592;</span>back</button>
                    <button className="form-next" name="next" type="submit" form="shipment">next</button>     
                </div>
            </form>
            
        </div>
    )
}

export default Shipment
