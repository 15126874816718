import React, { useState } from "react";
import "../Styles/SearchBar.scss"

const SearchBar = ({onFilter}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);

   const handleInputFocus = () => {
     setIsFocused(true);
   };

   const handleInputBlur = () => {
     setIsFocused(false);
   };


  const handleInputChange = (event) => {
    const {value} = event.target;
    setSearchTerm(value);
    // Throttle the filter function call
    setTimeout(() => {
      onFilter(value);
    }, 100); // Adjust the timeout value as needed
  };

  return (
    <div className={`search-container ${isFocused ? "focused" : ""}`}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className="search-input"
        placeholder="Search for products..."
      />
    </div>
  );
};

export default SearchBar;
