import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from "./Home/Components/Home"
import Category from "./Category/Components/Category"
import Navbar from "./Shared/Components/Navbar"
import SideBarCart from './Shared/Components/SideBarCart'
import Checkout from "./Checkout/Components/Checkout"
import AuthContextProvider from "./Context/Auth.Context" 
import ControlContextProvider from "./Context/Control.Context"
import {CartContextProvider} from "./Context/Cart.Context"
import {ProductsContextProvider} from "./Context/Products.Context"
import Success from './Shared/Components/Success';
import Signup from "./auth/Components/Signup"
import CompleteSignup from './auth/Components/CompleteSignup';
import ForgotPass from "./auth/Components/ForgotPass"
import SuccessOffline from './Shared/Components/SuccessOffline';
import ResetPassword from './auth/Components/ResetPassword';

function App() {
  return (
    <>
      <AuthContextProvider>
      <ControlContextProvider>
      <ProductsContextProvider>
      <CartContextProvider>
          <Navbar/>
          {/* <QuickViewModal/> */}
          <SideBarCart/>
          <Switch>
            <Route exact path="/" render={(routeProps) => <Home {...routeProps}/>}/>
            <Route exact path="/category/:title" render={(routeProps) => <Category {...routeProps}/> } />
            <Route exact path="/checkout/:page" render={(routeProps) => <Checkout {...routeProps}/>}/>
            <Route exact path="/account/signup" render={(routeProps) => <Signup {...routeProps}/>}/>
            <Route exact path="/account/forgot_pass" render={(routeProps) => <ForgotPass {...routeProps}/>}/>
            <Route exact path="/account/forgot_pass/reset/:username/:code" render={(routeProps) => <ResetPassword {...routeProps}/>}/>
            <Route exact path="/account/signup/success" render={(routeProps) => <CompleteSignup {...routeProps}/>}/>
            <Route exact path="/success/:id" render={(routeProps) => <Success {...routeProps}/>}/>
            <Route exact path="/checkout/success/invoice" render={(routeProps) => <SuccessOffline {...routeProps}/>}/>
            {/* <Route exact path="/report" render={(routeProps) => <PayWithOffline {...routeProps}/>}/> */}
          </Switch>
      </CartContextProvider>
      </ProductsContextProvider>
      </ControlContextProvider>
      </AuthContextProvider>
    </>
    
  );
}

export default App;
