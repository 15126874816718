import React from 'react'
import "../Styles/Newsletter.scss"

const NewsLetter = () => {
    return (
        <div className="news-container">
            <h1 className="news-header">
                Subscribe
            </h1>
            <h4 className="news-header-two">
                Penyesa monthly newsletter
                
            </h4>
            <form className="news-form">
                <input type="email" style={{paddingLeft: "2.5rem"}} className="news-input" placeholder="Email address here"/>
                <input type="submit" className="news-input" value="Click here to subscribe"/>
            </form>
            
        </div>
    )
}

export default NewsLetter
