import React, {useContext,useState} from 'react'
import {useParams,Link, NavLink} from "react-router-dom"
import "../Styles/ResetPassword.scss"
import {AuthContext} from "../../Context/Auth.Context"
import FormError from './FormError';
import Loader from "../../Shared/Components/Loader"

const hasUpperCase = (word) => {
    return word.toLowerCase() !== word
}
const hasLowerCase = (word) => {
    return word.toUpperCase() !== word;
}
const formValid = (passFormErrors) => {
    let valid = true;
    // validate form errors being empty
    Object.values(passFormErrors).forEach(val => {
        val.length > 0 && (valid = false);
      });
  
    return valid;
  };

const ResetPassword = () => {
    const initialState = {
        resetPassword : "",
        resetConfirmPassword : ""
    }
    const initialErrorState = {
        resetPassword : "Password is required",
        resetConfirmPassword : "Password do not match"
    }

    const {username,code} = useParams()
    const {isLoading,resetPasswordConfirm,resetError} = useContext(AuthContext)
    const [passwordInput,setPasswordInput] = useState(initialState)
    const [passwordError,setPasswordError] = useState(initialErrorState)
    const [isSubmit, setIsSubmit] = useState(false);
    const [isInitial, setIsInitial] = useState(true)
    const [changeState,setChangeState] = useState(false)
    


    console.log(`Username : ${username} Reset Code : ${code}`)
    

    const handleInputChange = (event)  => {
        const {name,value} = event.target;
        
        let tempVal = "";
        setPasswordInput({
            ...passwordInput,[name] : value
        })
        switch(name){
            case "resetPassword" :
                if( value.length < 1){
                    tempVal = "Password is required"
                }else if(value.length >= 1 && value.length < 8){
                    //resetError()
                    tempVal = "Password length should be greater than 7" 
                }else{
                    tempVal = hasUpperCase(value) && hasLowerCase(value) ? "" : "Password must contain at lest one upper case letter and one lower case letter";
                }       
                break;
            case "resetConfirmPassword" :
                if(value.length > 0){
                    tempVal = value === passwordInput.resetPassword ? "" : "Password does not match"
                }
                break;
        }
        setPasswordError({
            ...passwordError, [name] : tempVal
        })
        
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsInitial(false)
        if(formValid(passwordError)){

            setIsInitial(true);
            setPasswordInput(initialState)
            setPasswordError(initialErrorState)
            setChangeState(true)

        }else {
            console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
        }
    }
    return (
        <div className="passReset">
            <div className="passReset-container">
                <h1 className="passReset-title">Enter new Password</h1>
                
                {
                    changeState === false ? <>
                        <p className="passReset-p">
                            Enter your new password below to set a new password for your account
                        </p>
                        <div className="passReset-form">
                            <div className="signup-form-input ">
                                <label className="signup-form-label passReset-label" htmlFor="resetPass">Password</label>
                                <input 
                                    onChange ={handleInputChange}
                                    className="signup-input" required type="password" 
                                    name="resetPassword" id="resetPassword"/>
                                    {
                                        !isInitial && passwordError.resetPassword.length > 0 && <FormError error={passwordError.resetPassword}/>
                                    }
                                <label className="signup-form-label passReset-label" htmlFor="resetPassConfirm">Confirm Password</label>
                                <input 
                                    onChange ={handleInputChange}
                                    className="signup-input" required type="password" 
                                    name="resetConfirmPassword" id="resetConfirmPassword"/>
                                    {
                                        !isInitial && passwordError.resetConfirmPassword.length > 0 && <FormError error={passwordError.resetConfirmPassword}/>
                                    }
                
                            </div>
                            <div className="signup-button-group">
                                {
                                    isLoading && <Loader/>
                                }
                                <button className="signup-btn retry-btn" name="next-pass"
                                onClick={handleSubmit} disabled={isSubmit === true ? true :  false}>reset password</button>     
                            </div>
                        </div>
                    
                    </> : 
                    <>
                        <p className="passReset-p">
                            You have successfully changed your Penyesa password. You can follow the link below to the Penyesa Pantry homepage to login to your account.
                        </p>
                        <NavLink to="/" className="passReset-btn">Login</NavLink>
                    </>
                }
            </div>

        </div>
    )
}

export default ResetPassword
