import React from 'react'
import "../Styles/Footer.scss"
import {ReactComponent as Logo} from "../Images/FooterLogo.svg"
import {ReactComponent as Facebook} from "../Images/facebook.svg"
import {ReactComponent as Pinterest} from "../Images/pinterest.svg"
import {ReactComponent as Instagram } from "../Images/Instagram.svg"
import NewsLetter from "./NewsLetter"
// import Particles from "react-particles-js"

const Footer = () => {
    return (
        <div className="footer">
            <div className= "image-container">
                <Logo width="5rem"/>
            </div>
            <div className="footer-content">
                <div className="flank">
                    <a className="flank-link" href="#"><p>Privacy Policy</p></a>
                    <p>Terms &  Conditions</p>
                    <p>About Us</p>
                </div>
                <div className="middle">
                    <div className="social">
                        <a href="https://www.facebook.com/PenyesaSA/" className="social-links">
                            <Facebook className="social-icon"/>
                        </a>
                        <a href="https://www.instagram.com/PenyesaSA/" className="social-links">
                            <Pinterest className="social-icon"/>
                        </a>
                        <a href="http://www.pinterest.com/PenyesaSA" className="social-links">
                            <Instagram className="social-icon"/>
                        </a>
                    </div>
                    <NewsLetter/>
                    
                </div>
                <div className="flank">
                    <p>Delivery Info</p>
                    <p>Returns / Excahnges</p>
                    <p>Contact</p>
                </div>
            </div>
            <div className="footer-copy">
                &copy;{new Date().getFullYear()} PENYESA  
            </div>
            {/* <Particles
                style={{
                    position: "absolute",
                    top: "0",
                    left : "0",
                    zIndex : "0",
                    height : "50%"

                }}
                params={{
                    
                    "particles": {
                        "number": {
                            "value": 240,
                            "density": {
                                "enable": true,
                                "value_area": 1500
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": 0.07,
                            "distance" : 100,
                        },
                        "move": {
                            "enable" : true,
                            "straight" : false,
                            "random" : true,
                            "out_mode": "out",
                            "speed": 0.8
                            
                        },
                        "size": {
                            "value": 3,
                            "random": true,
                            "anim" : {
                                "enable" : true,
                                "speed" : 10,
                                "size_min" : 0.3,
                                "anim_sync": false,
                            }
                        },
                        "opacity": {
                            value : "0.05",
                            "anim": {
                                "enable": true,
                                "speed": 1,
                                "opacity_min": 0.15
                            }
                        }
                    },
                    
                    "interactivity": {
                        "events": {
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            }
                        },
                        "modes": {
                            "push": {
                                "particles_nb": 1
                            }
                        }
                    },
                    "retina_detect": true
                }} /> */}
        </div>
    )
}

export default Footer
