import React, {useContext,useState,useEffect,useRef} from 'react'
import {CartContext} from "../../Context/Cart.Context"
import {ControlContext} from "../../Context/Control.Context"
import {ReactComponent as CartIcon} from "../Images/CartIconWhite.svg"
import "../Styles/CartButton.scss"

const CartButton = () => {
     const ref = useRef(null);
    const [width,setWidth ] = useState(window.innerWidth)
    const {cartTotal,cartProducts} = useContext(CartContext);
    const {controlDispatch} = useContext(ControlContext)
    const Total = cartProducts.reduce((acc,current) => (acc + current.productPrice * current.productQty),0)
    
    const handleScroll = () => {
        //On Resize
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener("resize",handleScroll)
        handleScroll()
        
        return () => {
            window.removeEventListener("resize",handleScroll)
        }
    }, [])

    
    

    const handleCartControl = () => {
        controlDispatch({type : "CART"});
    }
   
    return (
        <div className="cartButton">
            {
                width > 500 && cartTotal > 0 &&
                <>
                    <div className="cartButton-total" onClick={handleCartControl}>
                        <div className="nav-icon--label">
                            <p>{cartTotal}</p>
                            <p>{`R${Total}`}</p>
                        </div>
                        <CartIcon fill="white"  className="nav-icon--display"/>
                        
                    </div>
                    
                </>
            }
        </div>
    )
}

export default CartButton 
