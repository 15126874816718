import React, {useContext} from 'react'
import {useHistory} from "react-router-dom"
import {ControlContext} from "../../Context/Control.Context"
import {CartContext} from "../../Context/Cart.Context"
import { ProductsContext} from "../../Context/Products.Context"
import "../Styles/SideBarCart.scss"
import {SwipeableDrawer as SwipeDrawer}
    from '@material-ui/core';

function SideBarCart() {
    const {cartOpenState, controlDispatch} = useContext(ControlContext)
    const {cartProducts, cartDispatch} =  useContext(CartContext)
    const {productsDispatch} = useContext(ProductsContext)
    const history = useHistory();
    const handleShowCart = ()=> {
        controlDispatch({type : "CART"})
    }

    //Updating Quantites
    const handleAddQuantity = (id)=> {
        cartDispatch({
            type : "AddProduct",
            itemID : id,
        })
        //Subtract Stock Total
        productsDispatch({type : "RemoveStockQty", productID: id})
    }
    const handleRemoveQuantity = (id, qty) => {
        if(qty > 1){
            cartDispatch({type : "Subtract", itemID : id})
        }else{
            cartDispatch({type: "RemoveProduct", itemID:id})
            //Remove Cart Label on Product
            productsDispatch({type:"RemoveCartLabel", productID : id})
        }
        //Add Stock Total
        productsDispatch({type : "AddStockQty", productID: id})
    }
    const handleCheckout = () => {
        cartDispatch({type : "cartID"});
        controlDispatch({type: "CART"})
        history.push("/checkout/shipping")
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        
      };
      const Total = cartProducts.reduce((acc,current) => acc + current.productQty * current.productPrice,0)
    return (
        <SwipeDrawer anchor={"right"} open={cartOpenState} className='sidebar'
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("left", true)} 
            disableScrollLock>
            <div className="sidebar-container">
                <div className="sidebar-nav">
                    <i className="far fa-window-close" onClick={handleShowCart}></i>
                    <h1 className="sidebar-heading">Your Cart</h1>
                    
                </div>
                {
                     cartProducts.length > 0 ? (
                        <div className="sidebar-content">
                            {
                                cartProducts.map(product => (
                                    <div className="sidebar-row" key={`CartItem-${product.productID}`}>
                                        <div className="sidebar-image">
                                            <img src={product.productImage} alt="Cart Item"/>
                                        </div>
                                        <div className="sidebar-text">
                                            <h5 className="cart-heading">{product.productBrand}</h5>
                                            <p className="cart-text">{product.productTitle}</p>
                                            <p className="cart-price">{`R${product.productPrice}`}</p>
                                        </div>
                                        <div className='sidebar-controls'>
                                            <i className={`fas fa-angle-up ${product.display_Stock <= 0 && "cartUP"}`} onClick={() => handleAddQuantity(product.productID)}></i>
                                            <p className="control-price">{product.productQty}</p>
                                            <i className="fas fa-angle-down" onClick={() => handleRemoveQuantity(product.productID, product.productQty)}></i>
                                        </div>
                                    </div>
                                 ))
                            }   
                        </div>
                     ):
                     (
                         <div className="sidebar-content sidebar-empty">
                             <h1 className="sidebar-empty-text">Cart Is Empty</h1>
                        </div>
                        
                     )
                }
               
               <div className="sidebar-footer">
                   <div className="sidebar-total">
                       { cartProducts.length > 0 && `Cart Total : R${Total}`}
                   </div>
                   {
                       cartProducts.length > 0 ? 
                       (
                            <button className="sidebar-checkout" onClick={handleCheckout}>
                                <i className="fas fa-check"></i>
                                Checkout
                            </button>
                       ):
                       (
                            <button className="sidebar-checkout" onClick={handleShowCart}>
                                <i className="fas fa-shopping-basket"></i>
                                Continue Shopping
                            </button>
                       )
                   }
               </div>
            </div>
        </SwipeDrawer>
    )
}

export default SideBarCart
