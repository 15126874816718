import React, {useContext} from 'react'
import {ControlContext} from "../../Context/Control.Context"

import "../Styles/QuickViewModal.scss"


const QuickViewModal = ({showModal,product, addCart, subtractCart}) => {
    const {controlDispatch} = useContext(ControlContext)
    const {id,title,brand,price,size,url,isInCart,description,quantity,stock_Qty,display_Stock} = product

    const handleOpenCart = () => {
        controlDispatch({type:"CART"})
        showModal(false)
    }

    return (
        <div className="modal">
            <div className="modal-container">
                <div className="modal-image">
                   <div className="modal-image-frame">
                   {
                       isInCart && (
                        <div className="modal-label" onClick={handleOpenCart} >
                            In Cart
                        </div>
                       )
                   }
                    <img src={url} alt="Product"/> 
                   </div>
                </div>
                <div className="modal-info">
                    <div className="modal-header">
                        <div className="modal-heading">
                            {`${brand} ${title}`}                     
                        </div>
                        <i className="far fa-window-close" onClick={() => showModal(false)} ></i>
                    </div>
                    <div className="modal-price">
                        {`R${price}`}
                    </div>
                    <div className="modal-text">
                        <div className={stock_Qty > 0 ? "modal-stock" : "modal-stock--out"}>
                            {stock_Qty > 0 && display_Stock > 0 ? `${display_Stock} In Stock` : "Out of stock"}
                        </div>
                        {
                            description ?(
                            <p className="prod-desc">
                                {description}
                            </p>
                            ) : (
                                <div className="prod-size">
                                    {`${brand} ${title} ${size}`}
                                </div>
                            )
                        }
                    </div>
                    {   display_Stock > 0 &&
                        <div className="modal-control" >
                            {
                                isInCart ? (
                                    <>
                                        <i className="far fa-minus-square" onClick={()=> subtractCart(id,quantity)}></i>
                                        <button className="modal-btn" onClick={handleOpenCart} >
                                            {quantity}
                                            <span>|</span>In Cart
                                        </button>
                                        <i className="fas fa-plus-square" onClick={() => addCart()}></i>
                                    </>
                                ) :
                                (
                                    <>
                                        <button className="modal-btn" onClick={addCart} >
                                        <i className="fas fa-shopping-basket"></i>
                                            <span>|</span>Add to Cart
                                        </button>
                                    </>
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default QuickViewModal
