import React from 'react'
import "../Styles/Review.scss"
import {ReactComponent as Payfast} from "../Images/PayfastLogoR.svg"
import {ReactComponent as Paypal} from "../Images/PaypalLogoR.svg"
import {ReactComponent as Transfer} from "../Images/BankTransLogo.svg"
import {useHistory} from "react-router-dom"
import PayWithPayFast from "./PayWithPayfast"
import PayWithPaypal from "./PayWithPaypal"
import PayWithOffline from "./PayWithOffline"

const Review = ({cartProducts,formState}) => {
    const history = useHistory();
    const paymentMethod = formState.paymentMethod;
    const Total = cartProducts.reduce((acc,current) => acc + current.productQty * current.productPrice,0).toFixed(2);
    const orderItem = cartProducts.map(item => (
        <li className="review-summary-item" key={`reviewOrder-${item.productID}`}>
            {`${item.productQty} x ${item.productBrand} ${item.productTitle}`}
            <span className="review-summary-price">{`R${item.productPrice}`}</span>
        </li>
    ))
    const paySelect = () => {
        if(formState.paymentMethod === "Payfast"){
            return <Payfast className="payment-image"/>
        }
        if(formState.paymentMethod === "Paypal"){
            return <Paypal className="payment-image"/>
        }
        if(formState.paymentMethod === "Transfer"){
            return <Transfer className="payment-image"/>;
        }
    }

    const RenderPayment = () => {
        if(cartProducts.length > 0){
            if(paymentMethod === "Payfast"){
                return  <PayWithPayFast formState={formState} cartProducts={cartProducts} Total={Total}/>
            }
            else if(paymentMethod === "Paypal"){
                return  <PayWithPaypal formState={formState} cartProducts={cartProducts} Total={Total}/>
            }
            else if(paymentMethod === "Transfer"){
                return <PayWithOffline  formState={formState} cartProducts={cartProducts} Total={Total}/>
            }
        }else{
            return <button  className="form-next" onClick={() => history.push('/category/Food')}>
                Shop
            </button>
        }
    }
    return (
        <div className="review">
            <h1 className="shipment-heading">Review Your Order</h1>
            <ul className="review-summary">
                {
                    orderItem
                }
                <div className="review-summary-total">{`Total`}
                    <span className="review-summary-price">{`R${Total}`}</span>
                </div>
            </ul>


             <div className="review-container">
             <div className="review-billing">
                    <div className="review-header">
                        <div className="review-point">

                        </div>
                        <h1 className="review-heading">Shipping Information</h1>
                    </div>
                    <ul className="review-information">
                    <li className="review-field">{`${formState.shipName} ${formState.shipSurname}`}</li>
                        <li className="review-field">{`${formState.shipEmail}`}</li>
                        <li className="review-field">{`${formState.shipAddress}`}</li>
                        <li className="review-field">{`${formState.shipCity}, ${formState.shipCountry}`}</li>
                        <li className="review-field">{`T: ${formState.shipMobile}`}</li>
                        <li className="review-field">{`${formState.deliveryInfo}`}</li>
                    </ul>
                </div>
                <div className="review-billing">
                    <div className="review-header">
                        <div className="review-point">

                        </div>
                        <h1 className="review-heading">Billing Information</h1>
                    </div>
                    <ul className="review-information">
                        <li className="review-field">{`${formState.billName} ${formState.billSurname}`}</li>
                        <li className="review-field">{`${formState.billEmail}`}</li>
                        <li className="review-field">{`${formState.billAddress}`}</li>
                        <li className="review-field">{`T: ${formState.billMobile}`}</li>
                    </ul>
                </div>
            </div>
            <div className="review-payment">
                    <h2 className="payment-heading">Payment Method :</h2>
                    <div className="payment-method">
                        <div className="payment-select">
                            {
                                paySelect()
                            }
                            <p className="payment-notice">
                                {
                                    paymentMethod === "Transfer" ? "Download your invoice below to place an order and find Penyesa Bank details.Use the given reference number or full name for payment reference. Your order will only be processed after full payment has been received."
                                    : "You will be redirected to the VCS website when you place an order."
                                }
                            </p>
                        </div>


                        <div className={`payment-order  ${paymentMethod === "Paypal" ? "back-paypal":""}`}>
                            <button className={`form-back`} name="back" onClick={() => history.push("/checkout/payment")}><span className="form-back-text">&#8592;</span>back</button>
                            {
                                RenderPayment()
                            }
                        </div>
                    </div>
            </div>

        </div>
    )
}

export default Review
